import { Component, OnInit } from '@angular/core';
import { StreamI18nService } from 'stream-chat-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private streamI18nService: StreamI18nService) {
    this.streamI18nService.setTranslation('fr');
  }

  ngOnInit(): void {
    setTimeout(() => {
      const loader = document.getElementById('loader');
      const cache = document.getElementById('cache');
      loader?.classList.add('hidden');
      cache?.classList.add('hidden');
      setTimeout(() => {
        loader?.remove();
      }, 300);
    }, 2500);

  }
}
