import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import {  Notification } from '../../dashboard/dashboard.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  usersForm = new FormGroup({});

  constructor(@Inject(MAT_DIALOG_DATA) public data: {users: Notification[], day: moment.Moment}, public dialogRef: MatDialogRef<ContactComponent>) { 
    data.users.sort((user1, user2) => moment(user1.user!.updatedAt).isAfter(user2.user!.updatedAt) ? -1 : 1);
    data.users.forEach(user => {
      this.usersForm.addControl(user.user!.id.toString(), new FormControl(false))
    });
  }

  getDispo(user: Notification) {
    const dispos = user['day' + this.data.day.date() as keyof Notification]! as string;
    return `disponible ${getDispoFromString(dispos)}`;
  }

  getLastActive(updatedAt: string) {
    return `actif ${moment(updatedAt).locale('fr').fromNow()}`;
  }

  sendMessages() {
    this.dialogRef.close(Object.keys(this.usersForm.value).filter(userId => this.usersForm.value[userId]));
  }

}

export function getDispoFromString(dispos: string) {
  if (dispos === '1111') {
    return 'toute la journée';
  }
  const dispoArray = [...dispos].map((dispo, index) => {
    const readableDispo = ['matin', 'midi', 'aprèm', 'après 18h'];
    return dispo === '1' && readableDispo[index];
  }).filter(dispo => dispo !== false);
  return `${dispoArray.join(' & ')}`;
}
