import { NgxMatDateFormats, NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Court, User } from '../dashboard.component';
import { CustomNgxDatetimeAdapter } from './customDateTime';

// https://stackoverflow.com/questions/62767985/how-can-i-customize-date-and-time-format-in-ngx-mat-datetime-picker
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-add-court-dialog',
  templateUrl: './add-court-dialog.component.html',
  styleUrls: ['./add-court-dialog.component.scss'],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useClass: CustomNgxDatetimeAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
})
export class AddCourtDialogComponent implements OnInit, OnDestroy {
  @ViewChild('picker') picker: any;
  maxlength = 300;
  courtForm: FormGroup = new FormGroup({
    booked: new FormControl(false, Validators.required),
    level: new FormArray([new FormControl((this.data.level && this.data.level[0]) || this.data.user.levelPTL - 1), new FormControl((this.data.level && this.data.level[1]) || this.data.user.levelPTL + 1)]),
    dateAndTime: new FormControl(moment(this.data.initialDate).set({minute: 0, second: 0, millisecond: 0}).add(1, 'hour').toDate(), Validators.required),
    duration: new FormControl(60, Validators.required),
    description: new FormControl('', [Validators.maxLength(this.maxlength), Validators.required]),
    arrondissement: new FormControl(1)
  });
  arrondissements!: Array<number>;
  @ViewChildren('chip', {}) chips?: any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = moment().set({minute: 0, second: 0, millisecond: 0}).add(1, 'hour').toDate();
  public stepHour = 1;
  public stepMinute = 15;
  public color = 'primary' as any;

  public showError = false;

  dateSubscription!: Subscription;
  formSubscription!: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {user: User, initialDate?: string, level?: number[], court?: Court}, public dialogRef: MatDialogRef<AddCourtDialogComponent>) {
  }

  ngOnInit(): void {
    if (this.data.court) {
      this.courtForm.patchValue(this.data.court);
    }
    this.arrondissements = Array.from(Array(20).keys());
    this.dateSubscription = this.courtForm.controls.dateAndTime.valueChanges.subscribe(value => {
      const mom = moment(value);
      mom.set({second: 0, millisecond: 0});
      this.courtForm.controls.dateAndTime.setValue(mom.toDate(), { emitEvent: false });
    })
    this.formSubscription = this.courtForm.valueChanges.subscribe(() => {
      this.showError = false;
    });
  }

  ngOnDestroy(): void {
    this.dateSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  get optionsSlider() {
    return  {
      floor: 0,
      ceil: 10,
      step: 0.5,
    }
  }

  onMinChange(value: MatSliderChange) {
    (this.courtForm.controls.level as FormArray).controls[0].setValue(value.value);
  }

  onMaxChange(value: MatSliderChange) {
    (this.courtForm.controls.level as FormArray).controls[1].setValue(value.value);
  }

  submitForm() {
    this.courtForm.markAllAsTouched();
    if (this.courtForm.valid) {
      // update court
      if (this.data.court) {
        this.dialogRef.close({...this.courtForm.value, level: this.courtForm.value.level.map((minOrMax: number) => Math.round(minOrMax * 100) / 100), id: this.data.court.id});
      } else {
        this.dialogRef.close({...this.courtForm.value, level: this.courtForm.value.level.map((minOrMax: number) => Math.round(minOrMax * 100) / 100)});
      }
    } else {
      this.showError = true;
    }
    
  }

}
