import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { HomeComponent } from './page/home/home.component';
import {MatDialogModule} from '@angular/material/dialog';
import { NotifDialogComponent } from './page/dashboard/notif-dialog/notif-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import { AddCourtDialogComponent } from './page/dashboard/add-court-dialog/add-court-dialog.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CourtsComponent } from './page/courts/courts.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddResultDialogComponent } from './page/dashboard/add-result-dialog/add-result-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import { HttpLoadingInterceptor } from './global-error-handler';
import { UserService } from './services/user.service';
import { OnlyLoggedInUsersGuard } from './login.guard';
import { CalendarComponent } from './page/calendar/calendar.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';

import { FaqComponent } from './page/faq/faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { UserComponent } from './page/user/user.component';
import { FindPageUserResolver } from './page/user/user.resolver';
import { ConfirmPassDialogComponent } from './page/user/confirm-dialog/confirm-dialog.component';
import { StreamChatModule, StreamAutocompleteTextareaModule } from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ChatComponent } from './page/chat/chat.component';
import { ChatService } from './page/chat/chat.service';
import { ContactComponent } from './page/calendar/contact/contact.component';
import { FaqCalendarComponent } from './page/calendar/faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    NotifDialogComponent,
    AddCourtDialogComponent,
    CourtsComponent,
    ConfirmDialogComponent,
    ConfirmPassDialogComponent,
    AddResultDialogComponent,
    CalendarComponent,
    FaqComponent,
    UserComponent,
    ChatComponent,
    ContactComponent,
    FaqCalendarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonToggleModule,
    NgxMatNativeDateModule,
    MatButtonModule,
    MatCardModule,
    BrowserAnimationsModule, 
    MatTabsModule,
    MatSliderModule,
    MatTooltipModule,
    MatExpansionModule,
    MatMenuModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr'
    }), 
    StreamAutocompleteTextareaModule, 
    StreamChatModule,
    MatBadgeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
    UserService,
    ChatService,
    OnlyLoggedInUsersGuard,
    FindPageUserResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
