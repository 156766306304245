import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmPassDialogComponent implements OnInit {

  password = new FormControl('');

  constructor(public dialogRef: MatDialogRef<ConfirmPassDialogComponent>) {
  }

  ngOnInit(): void {
    
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.password.value);
  }

}
