import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyLoggedInUsersGuard } from './login.guard';
import { CalendarComponent } from './page/calendar/calendar.component';
import { ChatComponent } from './page/chat/chat.component';
import { CourtsComponent } from './page/courts/courts.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { FaqComponent } from './page/faq/faq.component';
import { HomeComponent } from './page/home/home.component';
import { UserComponent } from './page/user/user.component';
import { FindPageUserResolver } from './page/user/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'login',
    component: HomeComponent,
  },
  {
    path: 'courts',
    component: CourtsComponent,
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'user/:id',
    component: UserComponent,
    canActivate: [OnlyLoggedInUsersGuard],
    resolve: {
      user: FindPageUserResolver
    }
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [OnlyLoggedInUsersGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
