import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { User, GameStatusEnum, Court } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-courts',
  templateUrl: './courts.component.html',
  styleUrls: ['./courts.component.scss'],
})
export class CourtsComponent implements OnInit {
  user!: User;
  courtsInfosFormatted!: Array<{
    id: number;
    title: string;
    subtitle: string;
    infos: string,
    status?: GameStatusEnum
  }>;
  courts!: Array<Court>;
  courtsShowed!: Array<Court>;
  returnUrl = '/';

  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private userService: UserService, private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.user = this.userService.user!;
    const params = await this.route.queryParams.pipe(take(1)).toPromise();
    this.updateCourts(params.date);
    this.returnUrl = params.returnUrl || '/';
  }

  //TODO: used also in dashboard => move to service
  ask(idCourt: number) {
    this.http.post('/api-ptl/dashboard/game', {idCourt})
    .pipe(
      catchError((error) => {
        this._snackBar.open("Erreur, la demande n'a pas abouti", 'Fermer');
        return throwError(error);
      })
    )
    .subscribe(async (res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
        const params = await this.route.queryParams.pipe(take(1)).toPromise();
        this.updateCourts(params.date);
      }
    });
  }

  private async updateCourts(date?: number) {
    this.courts = ((await this.http.get(`/api-ptl/dashboard/courts/${date || 'all'}`).pipe(take(1)).toPromise() as any).courts) as Array<Court>;
    this.courtsShowed = this.courts.filter(court => {
      const owner = court.games.find(game => game.status === GameStatusEnum.OWNER)!.user;
      return court.level[0] <= this.user.levelPTL && this.user.levelPTL <= court.level[1] && owner.id !== this.user.id;
    });
    this.courtsInfosFormatted = this.courtsShowed.map(court => {
      const owner = court.games.find(game => game.status === GameStatusEnum.OWNER)!.user;
      const currentUserStatus = court.games.find(game => game.idPlayer === this.user.id)
      return {
        id: court.id,
        title: `${
          moment(court.dateAndTime).locale('fr').format('dddd DD MMMM HH:mm')
        } - ${
          court.arrondissement
        }e arrondissement - ${
          Math.floor(court.duration/60)}h${court.duration%60 === 0 ? '' : 30
        }`,
        subtitle: `${owner.surname} ${owner.name} - ${owner.sexe} - Niveau PTL: ${owner.levelPTL} - Niveau FFT: ${owner.levelFFT}`,
        infos: court.description,
        status: currentUserStatus && currentUserStatus.status
      }
    });
  }
}
