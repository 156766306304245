<mat-toolbar>
  <span class="long-title">PARIS TENNIS LIGUE</span>
  <span class="short-title">PTL</span>
  <span class="example-spacer"></span>
  <a
    href="https://www.facebook.com/groups/966052414120313"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="facebook"
  >
    <mat-icon style="color: #3B5998;">facebook</mat-icon>
  </a>
  <a
    href="mailto:contact@paris-tennis-ligue.fr"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="contact"
  >
    <mat-icon>contact_mail</mat-icon>
  </a>
  <span class="example-spacer"></span>
  <a
    routerLink="/chat"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="faq"
  >
    <mat-icon [matBadge]="chatService.unreadCount | async" matBadgeColor="warn" matBadgeSize="small">chat</mat-icon>
  </a>
  <button
    mat-icon-button
    (click)="openDialog()"
    class="example-icon favorite-icon"
    aria-label="notifications"
  >
    <mat-icon>notifications</mat-icon>
  </button>
  <a
    routerLink="/faq"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="faq"
  >
    <mat-icon>help_outline</mat-icon>
  </a>
  <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="logout"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a [routerLink]="'/user/' + user.id" mat-menu-item>
      <span>Ton Profil</span>
    </a>
    <button (click)="logout()" mat-menu-item>
      <span>Déconnexion</span>
    </button>
  </mat-menu>
</mat-toolbar>

<div class="content">
  <div class="example-container mat-elevation-z8">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    
    <div class="example-table-container">
      <table
        mat-table
        [dataSource]="players"
        class="example-table"
        matSort
        matSortActive="created"
        matSortDisableClear
        matSortDirection="desc"
      >
        <!-- Number Column -->
        <ng-container matColumnDef="ranking">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nom</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="rankPTL">
          <th mat-header-cell *matHeaderCellDef>Classement PTL</th>
          <td mat-cell *matCellDef="let row">{{ row.levelPTL | number: '1.1-1' }}</td>
        </ng-container>

        <ng-container matColumnDef="rankOutside">
          <th mat-header-cell *matHeaderCellDef>Classement FFT</th>
          <td mat-cell *matCellDef="let row">{{ row.levelFFT }}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          #matrow
          mat-row
          [id]="'player' + row.id"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [style.background]="row.id === user.id ? 'lightblue' : ''"
          ></tr>
      </table>
    </div>
  </div>

  <div class="courts" style="height: 100%;">
    <div style="margin-bottom: 16px;">
      <div style="margin-bottom: 8px">Trouver un partenaire:</div>
      <a
      routerLink="/calendar"
        mat-raised-button
        color="primary"
        style="margin-right: 8px"
        class="button-find"
        >
        Calendrier PTL
      </a>
      <button
      (click)="addCourt()"
      mat-raised-button
        style="margin-right: 8px"
        class="button-find"
        >
        J'ai un court de tennis
      </button>
      <a routerLink="/courts" mat-raised-button>Je cherche un court de tennis</a>
    </div>
    <mat-divider></mat-divider>
    <div style="margin-top: 16px; height: 90%;">
      <div style="margin-bottom: 4px;">Mes courts</div>
      <div class="courts" style="height: 90%; overflow-y: scroll;">
        <mat-card *ngFor="let court of gamesInfosFormatted; let i = index" class="card" style="margin: 8px">
          <button 
            *ngIf="court.isOwner && court.status !== 'SENT_RESULT' && court.status !== 'RECEIVED_RESULT' && court.status !== 'ENDED'" 
            (click)="modifyCourt(i)" class="edit-court" mat-icon-button color="primary">
            <mat-icon >edit</mat-icon>
          </button>
          <div *ngIf="hasAcceptedPlayer(court)" class="other-player"> Au moins un autre joueur a déjà été accepté</div>
          <mat-card-title>{{court.title}}</mat-card-title>
          <mat-card-subtitle>{{court.subtitle}} <button *ngIf="!court.isOwner && court.games" (click)="contactOwner(court.games!)" mat-button color="primary">Contacter</button></mat-card-subtitle>
          <mat-card-content>
            <p>{{court.infos}}</p>
            <ng-container *ngIf="court.isOwner">
              <div *ngFor="let game of court.games; let i = index">
                <div class="player-info" style="display: flex; align-items: center;">
                  <div>{{game.user.surname}} {{game.user.name}} - {{game.user.sexe}} - Niveau PTL: {{game.user.levelPTL | number: '1.1-1'}} - Niveau FFT: {{game.user.levelFFT}}</div>
                  <button *ngIf="game.status === 'ASKED'" (click)="accept(game.id)" [disabled]="!court.booked" mat-button color="primary">Accepter</button>
                  <mat-icon 
                  *ngIf="game.status === 'ASKED' && !court.booked"
                  style="font-size: 0.9rem; width: 0.9rem; height: 0.9rem; cursor: pointer;" 
                  #tooltip="matTooltip"
                  matTooltip="Réserve le court pour pouvoir accepter des joueurs"
                  matTooltipPosition="below">info_outline</mat-icon>
                  <button *ngIf="game.status === 'CONFIRMED'" (click)="unaccept(game.id)" mat-button color="warn">Annuler</button>
                  <button (click)="contact(game.user, court.games!)" mat-button color="secondary">Contacter</button>
                </div>
                <mat-divider *ngIf="court.games!.length > 1 && i !== court.games!.length - 1"></mat-divider>
              </div>
            </ng-container>
          </mat-card-content>
          <mat-card-actions align='end'>
            <button *ngIf="court.status === 'SENT'" mat-button disabled>PROPOSÉ</button>
            <button *ngIf="court.status === 'RECEIVED'" mat-button disabled>DEMANDES REÇUS</button>
            <button *ngIf="court.status === 'ASKED'" mat-button disabled>DEMANDE EN COURS</button>
            <button *ngIf="court.status === 'CONFIRMED'" mat-button disabled>CONFIRMÉ</button>
            <button *ngIf="court.status === 'ENDED' || court.status === 'SENT_RESULT' || court.status === 'RECEIVED_RESULT'" mat-button disabled>TERMINÉ</button>
            <button *ngIf="court.status !== 'SENT_RESULT' && court.status !== 'RECEIVED_RESULT' && court.status !== 'ENDED'" (click)="cancel(i)" mat-button color="warn">Annuler</button>
            <button *ngIf="court.status === 'ENDED'" (click)="addResult(i)" mat-button color="primary">Ajouter un résultat</button>
            <button *ngIf="court.status === 'SENT_RESULT'" (click)="cancelResult(i)" mat-button color="warn">Annuler le résultat</button>
            <button *ngIf="court.status === 'RECEIVED_RESULT'" (click)="confirmResult(i)" mat-button color="primary">Voir et valider le résultat</button>
            <button *ngIf="court.status === 'ARCHIVE'" (click)="finishGame(i)" mat-button color="primary">Archiver</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    
  </div>
</div>
