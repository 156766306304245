<h2 mat-dialog-title>Confirmer le changement</h2>
<mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Mot de passe</mat-label>
    <input [formControl]="password" matInput type="password">
    <mat-hint>Tape ton mot de passe <strong>actuel</strong></mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="cancel()" mat-button mat-dialog-close color="warn">Annuler</button>
  <button (click)="confirm()" [disabled]="!password.value" mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Confirmer</button>
</mat-dialog-actions>
  