import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { ChatService } from '../chat/chat.service';
import { AddCourtDialogComponent } from './add-court-dialog/add-court-dialog.component';
import { AddResultDialogComponent } from './add-result-dialog/add-result-dialog.component';
import { NotifDialogComponent } from './notif-dialog/notif-dialog.component';

export interface Calendar {
  day1: Notification[] | string,
  day2: Notification[] | string,
  day3: Notification[] | string,
  day4: Notification[] | string,
  day5: Notification[] | string,
  day6: Notification[] | string,
  day7: Notification[] | string,
  day8: Notification[] | string,
  day9: Notification[] | string,
  day10: Notification[] | string,
  day11: Notification[] | string,
  day12: Notification[] | string,
  day13: Notification[] | string,
  day14: Notification[] | string,
  day15: Notification[] | string,
  day16: Notification[] | string,
  day17: Notification[] | string,
  day18: Notification[] | string,
  day19: Notification[] | string,
  day20: Notification[] | string,
  day21: Notification[] | string,
  day22: Notification[] | string,
  day23: Notification[] | string,
  day24: Notification[] | string,
  day25: Notification[] | string,
  day26: Notification[] | string,
  day27: Notification[] | string,
  day28: Notification[] | string,
  day29: Notification[] | string,
  day30: Notification[] | string,
  day31: Notification[] | string,
}
export interface Player extends User {
  status?: GameStatusEnum;
}

enum COURT_STATUS {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  ASKED = 'ASKED',
  CONFIRMED = "CONFIRMED",
  ENDED = "ENDED",
  SENT_RESULT = "SENT_RESULT",
  RECEIVED_RESULT = "RECEIVED_RESULT",
  ARCHIVE = "ARCHIVE"
}

export enum GameStatusEnum {
  OWNER = "OWNER",
  ASKED = "ASKED",
  CONFIRMED = "CONFIRMED",
}
export interface Court {
  id: number;
  booked: boolean;
  arrondissement: number;
  dateAndTime: Date;
  duration: number;
  description: string;
  games: Array<Game>;
  level: Array<number>;
}

export interface Game {
  id: number;
  idPlayer: number;
  status: GameStatusEnum;
  user: User;
  court: Court;
  result: boolean | null;
  resultInfo: string | null;
}

export interface User {
  id: number;
  sexe: string;
  levelPTL: number;
  levelFFT: string;
  name: string;
  surname: string;
  email?: string;
  phone?: string;
  sponsorCode: string;
  updatedAt: string;
}

export interface Notification extends Calendar {
  activated: boolean,
  level: Array<number>,
  arrondissements: Array<number>,
  monday: number,
  tuesday: number,
  wednesday: number,
  thursday: number,
  friday: number,
  saturday: number,
  sunday: number,
  user?: User,
}

@Component({
  selector: 'app-notifications',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  user!: User;
  players!: Player[];
  displayedColumns: string[] = ['ranking', 'name', 'rankPTL', 'rankOutside'];
  resultsLength = 0;
  isLoadingResults = false;
  gamesInfos!: Array<Game>;
  gamesInfosFormatted!: Array<{
    isOwner: boolean;
    booked: boolean;
    title: string;
    subtitle: string;
    infos: string,
    status: COURT_STATUS,
    games?: Array<Game>
  }>;
  notification?: Notification;

  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;

  constructor(
    public dialog: MatDialog, 
    private http: HttpClient, 
    private _snackBar: MatSnackBar, 
    private route: ActivatedRoute, 
    private _router: Router, 
    private userService: UserService, 
    public chatService: ChatService
  ) {
  }

  async ngOnInit() {
    this.user = this.userService.user!;
    const params = await this.route.queryParams.pipe(take(1)).toPromise();
    if (params && params.court) {
      await this.ask(params.court);
    }
    await this.updateDashboard();
    if (params && params.showNotif) {
      this.openDialog();
    }
    if (params && params.addCourt) {
      this.addCourt();
    }
  }

  ngAfterViewInit(): void {
    this.showElement(this.user.id);
  }

  showElement(index: number) {
    let row = this.rows.find(
      (row) =>
        row.element.nativeElement.getAttribute('id') === `player${index + 6}`
    );
    if (row != null) {
      row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      return;
    }
  }

  logout() {
    this.http.delete('/api-ptl/user/logout')
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.chatService.disconnectUser();
          this._router.navigate(['login']);
          this.userService.user = undefined;
        }
      });
  }

  async openDialog() {
    // TODO: use real data if exists
    const dialogRef = this.dialog.open(NotifDialogComponent, {
      data: this.notification,
    });

    const notif = await dialogRef.afterClosed().pipe(take(1)).toPromise();
    if (notif) {
      this.http.post('/api-ptl/dashboard/notification', notif)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur lors de la mise à jour des notifications", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
          this._snackBar.open("Ta configuration des notifications a bien été mise à jour", 'Fermer');
          this._router.navigate(['/calendar']);
        }
      });
    }
    
  }

  async addCourt() {
    const dialogRef = this.dialog.open(AddCourtDialogComponent, {
      data: {user: this.user}
    });

    const court = await dialogRef.afterClosed().pipe(take(1)).toPromise();
    if (court) {
      this.http.post('/api-ptl/dashboard/court', court)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Le court n'a pas pu être ajouté", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    }
    
  }

  async modifyCourt(idGame: number) {
    const courtExisting = this.gamesInfos[idGame].court;
    const dialogRef = this.dialog.open(AddCourtDialogComponent, {
      data: {user: this.user, court: courtExisting }
    });

    const court = await dialogRef.afterClosed().pipe(take(1)).toPromise();
    if (court) {
      this.http.put('/api-ptl/dashboard/court', court)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Le court n'a pas pu être modifié", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    }
    
  }

  accept(idGame: number) {
    this.http.put(`/api-ptl/dashboard/game/${idGame}/accept`, {value: GameStatusEnum.CONFIRMED})
    .pipe(
      catchError((error) => {
        this._snackBar.open("Le joueur n'a pas pu être accepté", 'Fermer');
        return throwError(error);
      })
    )
    .subscribe((res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
        this._snackBar.open("Le joueur a été accepté, contacte-le pour lui donner tous les détails", 'Fermer');
        this.updateDashboard();
      }
    });
  }

  unaccept(idGame: number) {
    this.http.put(`/api-ptl/dashboard/game/${idGame}/accept`, {value: GameStatusEnum.ASKED})
    .pipe(
      catchError((error) => {
        this._snackBar.open("Erreur la participation du joueur n'a pas pu être annulé", 'Fermer');
        return throwError(error);
      })
    )
    .subscribe((res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
        this._snackBar.open("La participation du joueur a été annulé, n'oublie pas de lui confirmer par message", 'Fermer');
        this.updateDashboard();
      }
    });
  }

  async contact(player: User, games: Game[]) {
    await this.chatService.createNewChannels([{
      id: player.id,
      name: player.surname
    }]);
    this.contactNotif((games[0] as any).idCourt);
    this._router.navigate([`/chat`]);
  }

  async contactOwner(games: Game[]) {
    const owner = games.find(game => game.status === GameStatusEnum.OWNER)!.user;
    await this.chatService.createNewChannels([{
      id: owner.id,
      name: owner.surname
    }]);
    this.contactNotif((games[0] as any).idCourt);
    this._router.navigate([`/chat`]);
  }

  async cancel(index: number) {
    const owner = this.gamesInfos[index].court.games.find(game => game.status === GameStatusEnum.OWNER)!.user;
    const currentUserGame = this.gamesInfos[index].court.games.find(game => game.idPlayer === this.user.id)!;
    if (owner.id === this.user.id) {
      const canCancel = await this.confirmDialog('Annuler le court ?', 'Es-tu sûr ? N\'oublie pas de prévenir les joueurs déjà confirmés !');
      canCancel && this.http.delete(`/api-ptl/dashboard/court/${this.gamesInfos[index].court.id}`)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur, le court n'a pas pu être annulé", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    } else {
      const canCancel = await this.confirmDialog('Annuler ta participation ?', 'Es-tu sûr ? N\'oublie pas de prévenir l\'organisateur si tu étais confirmé !');
      canCancel && this.http.delete(`/api-ptl/dashboard/game/${currentUserGame.id}`)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur, le court n'a pas pu être annulé", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    }
    
  }

  async addResult(index: number) {
    const dialogRef = this.dialog.open(AddResultDialogComponent, {
      minWidth: window.screen.width < 500 ? window.screen.width : 500
    });
    const result = await dialogRef.afterClosed().pipe(take(1)).toPromise();
    if (result) {
      this.http.put(`/api-ptl/dashboard/game/${this.gamesInfos[index].id}/result`, result)
        .pipe(
          catchError((error) => {
            this._snackBar.open("Erreur, le résultat n'a pas pu être ajouté", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this.updateDashboard();
          }
        });
    }
  }

  async confirmResult(index: number) {
    const {result, resultInfo } = this.gamesInfos[index].court.games.find(game => game.idPlayer !== this.user.id  && (game.status === GameStatusEnum.OWNER || game.status === GameStatusEnum.CONFIRMED))!;
    const resultString = result ? 'perdu' : 'gagné';
    const resultInfoString = JSON.parse(resultInfo!).reduce((finalString: string, set: {me: number, opponent:number}) => {
      finalString += ` ${set.opponent}-${set.me}`;
      return finalString;
    }, '');
    const confirm = await this.confirmDialog('Résultat valide ?', `Ton adversaire a posté ce résultat: Tu as ${resultString}<strong>${resultInfoString}</strong>.<br/>En cas d'erreur, ne valide pas le résultat et contacte le pour qu'il le change!`)
    if (confirm) {
      this.http.put(`/api-ptl/dashboard/game/${this.gamesInfos[index].id}/result/confirm`, {
        competition: true,
        won: !result,
        score: JSON.parse(resultInfo!).map((set: {me: number, opponent:number}) => {
          return {
            me: set.opponent,
            opponent: set.me
          }
        })
      })
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur, le résultat n'a pas pu être validé", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    }
  }

  cancelResult(index: number) {
    this.http.delete(`/api-ptl/dashboard/game/${this.gamesInfos[index].id}/result`)
    .pipe(
      catchError((error) => {
        this._snackBar.open("Erreur, le résultat n'a pas pu être supprimé", 'Fermer');
        return throwError(error);
      })
    )
    .subscribe((res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
        this.updateDashboard();
      }
    });
  }

  async finishGame(index: number) {
    const confirm = await this.confirmDialog('Archiver le match', 'Il disparaitra de ton tableau de bord et tu ne pourras plus faire de changements ensuite!');
    if (confirm) {
      this.http.put(`/api-ptl/dashboard/game/${this.gamesInfos[index].id}/finish`, {})
      .pipe(
        catchError((error) => {
          this._snackBar.open("Erreur, le match n'a pas pu être archivé", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.updateDashboard();
        }
      });
    }
  }

  hasAcceptedPlayer(court: any) {
    return court.status === 'ASKED' && court.games && court.games.some((game: any) => game.status === 'CONFIRMED' && !game.canceled);
  }

  private ask(idCourt: number) {
    this.http.post('/api-ptl/dashboard/game', {idCourt})
    .pipe(
      catchError((error) => {
        // déjà demandé à participer
        if (error.error && error.error.error && typeof error.error.error === 'string') {
          this._snackBar.open(error.error.error, 'Fermer')
        } else {
          this._snackBar.open("Erreur, la demande n'a pas abouti", 'Fermer');
        }
        return throwError(error);
      })
    )
    .subscribe((res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
        this._snackBar.open("La demande a bien été envoyée", 'Fermer');
      }
    });
  }

  /**
   * Send notif when player tries to contact other player
   */
  private contactNotif(idCourt: number) {
    this.http.post('/api-ptl/dashboard/game/contact', {idCourt})
    .pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
    .subscribe((res) => {
      const resTyped = res as any
      if (resTyped?.message === 'ok') {
      }
    });
  }

  private async updateDashboard() {
    try {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      const data = (await this.http.get('/api-ptl/dashboard', {headers: headers as any, withCredentials: true}).pipe(take(1)).toPromise() as {games: Array<Game>, users: Array<User>, notification?: Notification});
      if (data) {
        this.players = data.users.map(user => {
          return {
            ...user,
            name: `${user.surname} ${user.name}`,
          }
        });
        this.notification = data.notification;
        this.gamesInfos = data.games;
        this.gamesInfosFormatted = this.gamesInfos.map((game, index) => {
          const owner = game.court.games.find(game => game.status === GameStatusEnum.OWNER)!.user;
          const currentUserStatus = game.court.games.find(game => game.idPlayer === this.user.id);
          return {
            title: `${
              moment(game.court.dateAndTime).locale('fr').format('dddd DD MMMM HH:mm')
            } - ${
              game.court.arrondissement
            }e arrondissement - ${
              Math.floor(game.court.duration/60)}h${game.court.duration%60 === 0 ? '' : 30
            }`,
            isOwner: owner.id === this.user.id, 
            booked: game.court.booked,
            subtitle: owner.id === this.user.id ? 'Organisateur' : `${owner.surname} ${owner.name} - ${owner.sexe} - Niveau PTL: ${owner.levelPTL} - Niveau FFT: ${owner.levelFFT}`,
            infos: game.court.description,
            status: this.getStatusCourt(index),
            games: game.court.games.filter(game => game.idPlayer !== this.user.id),
            canContact: owner.id !== this.user.id && currentUserStatus?.status === GameStatusEnum.CONFIRMED
          }
        });
      }
    } catch (error) {
      this._router.navigate(['login']);
    }
  }

  private async confirmDialog(title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        message
      },
    });
    return dialogRef.afterClosed().pipe(take(1)).toPromise();
  }

  private getStatusCourt(index: number): COURT_STATUS {
    const isDouble = this.gamesInfos[index].court.games.filter(game => game.status === GameStatusEnum.CONFIRMED).length > 1;
    const owner = this.gamesInfos[index].court.games.find(game => game.status === GameStatusEnum.OWNER)!.user;
    if (isDouble && new Date(this.gamesInfos[index].court.dateAndTime) < new Date()) {
      return COURT_STATUS.ARCHIVE;
    } else if (this.gamesInfos[index].result !== null) {
      return COURT_STATUS.SENT_RESULT;
    } else if (owner.id === this.user.id) {
      if (this.gamesInfos[index].court.games.length === 1) {
        // date has passed and no proposal received
        if (new Date(this.gamesInfos[index].court.dateAndTime) < new Date()) {
          return COURT_STATUS.ARCHIVE;
        }
        return COURT_STATUS.SENT;
      } else if (this.gamesInfos[index].court.games.some(game => game.status === GameStatusEnum.CONFIRMED)) {
        if (this.gamesInfos[index].court.games.some(game => game.result !== null)) {
          return COURT_STATUS.RECEIVED_RESULT;
        }
        if (new Date(this.gamesInfos[index].court.dateAndTime) < new Date()) {
          return COURT_STATUS.ENDED;
        }
        return COURT_STATUS.CONFIRMED;
      } else {
        return COURT_STATUS.RECEIVED
      }
    } else {
      const currentUserStatus = this.gamesInfos[index].court.games.find(game => game.idPlayer === this.user.id);
      if (currentUserStatus?.status === GameStatusEnum.ASKED) {
        // date has passed and not accepted
        if (new Date(this.gamesInfos[index].court.dateAndTime) < new Date()) {
          return COURT_STATUS.ARCHIVE;
        }
        return COURT_STATUS.ASKED;
      } else {
        if (this.gamesInfos[index].court.games.some(game => game.result !== null)) {
          return COURT_STATUS.RECEIVED_RESULT;
        }
        if (new Date(this.gamesInfos[index].court.dateAndTime) < new Date()) {
          return COURT_STATUS.ENDED;
        }
        return COURT_STATUS.CONFIRMED
      }
    }
  }
}
