import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Game, GameStatusEnum, User } from '../dashboard/dashboard.component';
import { ConfirmPassDialogComponent } from './confirm-dialog/confirm-dialog.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty && control.touched));
  }
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  user!: User;
  userPage!: User;
  games?: Game[];

  userForm?: FormGroup;

  matcher = new MyErrorStateMatcher();

  constructor(private userService: UserService, private route: ActivatedRoute, private http: HttpClient, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.user = this.userService.user!;
    this.userPage = this.route.snapshot.data.user;
    if (this.isLoggedUser) {
      this.instantiateUserForm();
    }
  }

  get isLoggedUser() {
    return this.user.id == this.userPage.id;
  }

  public async getGameHistory() {
    if (!this.games) {
      try {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        const data = (await this.http.get(`/api-ptl/user/${this.userPage.id}/gamesEnded`, {headers: headers as any, withCredentials: true}).pipe(take(1)).toPromise()) as Game[];
        this.games = data;
      } catch (error) {
        this._snackBar.open('Erreur lors du chargement de l\'historique', 'Fermer');
      }
    }
  }

  public async saveField(fieldName: string) {
    let password;
    if (fieldName !== 'surname' && fieldName !== 'name' && fieldName !== 'levelFFT') {
      password = await this.confirmDialog();
    }
    if (password || !(fieldName !== 'surname' && fieldName !== 'name' && fieldName !== 'levelFFT')) {
      this.http.put(`/api-ptl/user/${this.userPage.id}`, {
        fieldName,
        value: this.userForm?.value[fieldName],
        password
      })
      .pipe(
        catchError((error) => {
          this._snackBar.open("Le changement n'a pas pu être pris en compte", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          (async () => {
            this._snackBar.open("Le changement a bien été pris en compte", 'Fermer', {duration: 2000});
            await this.userService.getUserData(true);
            this.user = this.userService.user!;
            const params = await this.route.paramMap.pipe(take(1)).toPromise();
            this.userPage = await this.http.get(`/api-ptl/user/${+params.get('id')!}`).pipe(take(1)).toPromise() as User;
            this.instantiateUserForm();
          })()
        }
      });
    }
  }

  isSaveDisabled(fieldName: string) {
    return this.userForm?.controls[fieldName].value === this.userPage[fieldName as keyof User] || this.userForm?.controls[fieldName].invalid;
  }

  public getPrettyDate(date: Date) {
    return moment(date).locale('fr').fromNow();
  }

  getPrettyResult(game: Game) {
    if (game.result !== null) {
      return game.result ? 'GAGNÉ' : 'PERDU'
    } else if (game.resultInfo) {
      return 'LOISIR'
    } else {
      return 'DOUBLE';
    }
  }

  getPrettyResultInfo(game: Game) {
    if (game.result !== null && game.resultInfo) {
      return JSON.parse(game.resultInfo).reduce((finalString: string, set: {me: number, opponent:number}) => {
        finalString += ` ${set.me}-${set.opponent}`;
        return finalString;
      }, '');
    }
    return ''
  }

  contreOuAvec(game: Game) {
    return game.result !== null ? 'contre' : 'avec';
  }

  opponents(game: Game) {
    return game.court.games.filter((nestedGame: Game) => {
      return nestedGame.id !== game.id && game.status !== GameStatusEnum.ASKED;
    });
  }

  isRedOrGreen(game: Game) {
    return game.result === null ? '' : game.result ? 'green' : 'red';
  }

  private instantiateUserForm() {
    if (!this.userForm) {
      this.userForm = new FormGroup({
        email: new FormControl(this.userPage.email, [Validators.email, Validators.required]),
        surname: new FormControl(this.userPage.surname, [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(/^[a-z ,.'-]+$/i)]),
        name: new FormControl(this.userPage.name, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-z ,.'-]+$/i)]),
        phone: new FormControl(this.userPage.phone, [Validators.required, Validators.maxLength(15)]),
        levelFFT: new FormControl(this.userPage.levelFFT, [Validators.required]),
        password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern(/^(?=.*?[0-9])(?=.*?[A-Za-z]).+$/)]),
      });
    }
    this.userForm.reset(this.userPage);
  }

  private async confirmDialog() {
    const dialogRef = this.dialog.open(ConfirmPassDialogComponent, {});
    return dialogRef.afterClosed().pipe(take(1)).toPromise();
  }
}
