<mat-toolbar>
  <a
    routerLink=""
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>FAQ</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div class="faq">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 'PTL'">
      <mat-expansion-panel-header>
        <mat-panel-title> Qu'est-ce que Paris Tennis Ligue? </mat-panel-title>
        <mat-panel-description>🎾📈🏆</mat-panel-description>
      </mat-expansion-panel-header>
      <p>Paris Tennis Ligue est une plateforme qui te permet de:</p>
      <ul>
        <li>
          Trouver des partenaires pour jouer au tennis selon tes critères
          <button (click)="goTo('receiveNotif')" mat-button color="primary">
            En savoir plus
            <mat-icon style="transform: scale(0.7)">open_in_new</mat-icon>
          </button>
          <ul>
            <li>Niveau</li>
            <li>Localisation</li>
            <li>Horaires</li>
          </ul>
        </li>
        <li>
          Rejoindre des ligues
          <button (click)="goTo('ligue')" mat-button color="primary">
            En savoir plus
            <mat-icon style="transform: scale(0.7)">open_in_new</mat-icon>
          </button>
        </li>
        <li>
          Accéder à des tournois
          <button (click)="goTo('turnament')" mat-button color="primary">
            En savoir plus
            <mat-icon style="transform: scale(0.7)">open_in_new</mat-icon>
          </button>
        </li>
        <li>
          Voir ton évolution avec un classement, des graphiques, des
          statistiques
        </li>
      </ul>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'receiveNotif'">
      <mat-expansion-panel-header>
        <mat-panel-title>Recevoir des notifications</mat-panel-title>
        <mat-panel-description
          >Je suis dispo les jeudis après 18 h, niveau 30/3 à 30, nord de
          Paris</mat-panel-description
        >
      </mat-expansion-panel-header>
      <p>Configure ton compte pour recevoir des alertes selon tes envies.</p>
      <img src="/assets/notifs.png" alt="notif" style="width: 50%;">
      <p>Sur la photo ci-dessus, le joueur recevra une alerte lorsqu'un joueur de niveau PTL entre 1 et 4, propose un court dans n'importe quel arrondissement sauf le 6, 11 ou 15ᵉ.</p>
      <p>
        Si tu reçois une notif qui t'intéresse, clique simplement sur 'Demander
        à rejoindre', l'organisateur recevra ton profil et tes coordonnés.
      </p>
      <p>S'il accepte ta demande, tu recevras ses coordonnés.</p>
      <p>
        Maintenant, la balle est dans votre camp, mettez-vous d'accord sur
        l'heure et l'endroit du rendez-vous, le mode (<button
         (click)="goTo('mode')"
          mat-button
          color="primary"
        >
          Compétition</button
        >, <button (click)="goTo('mode')" mat-button color="primary">Loisir</button>), etc
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'calendar'">
      <mat-expansion-panel-header>
        <mat-panel-title>Le calendrier</mat-panel-title>
        <mat-panel-description>
          Toutes les disponibilités d'un seul coup d'œil
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div style="display: flex; width: 100%; justify-content: center;">
        <img src="/assets/calendar.png" alt="calendrier" style="width: 50%;">
      </div>
      <p>Le calendrier est une page qui te permet d'avoir beaucoup d'informations sur les disponibilités de la communauté PTL mais aussi de renseigner les autres joueurs sur tes disponibilités.</p>
      <p>Voici une vue plus précise de 2 jours du calendrier.</p>
      <img src="/assets/calendarFocus.png" alt="focus calendrier" style="width: 50%;">
      <h3>Renseigner sur mes disponibilités</h3>
      <p><span class="number">1</span> Si le jour est blanc, cela signifie que tu n'es pas disponible. L'indicateur de disponibilités dans le coin en haut à gauche est rouge.</p>
      <p>En cliquant sur le deuxième jour (demain <span class="number">3</span>), la couleur devient bleue, et à côté du <span class="number">2</span>, l'indicateur de disponibilités devient vert. Cela aura 3 conséquences :</p>
      <ul>
        <li>Tu recevras des notifications pour les nouveaux courts proposés ce jour-là</li>
        <li>Les joueurs verront que tu es disponible. Voir <span class="number">4</span></li>
        <li>Tu pourras ajouter un court ce jour-là et accéder aux courts disponibles. Voir <span class="number">6</span> et <span class="number">7</span></li>
      </ul>
      <p><span class="number">2</span> En cliquant sur l'indicateur de disponibilités, un menu apparait qui te permet de renseigner plus exactement tes disponibilités ce jour-là. Tu peux dire, par exemple, que tu n'es disponible que le matin. Dans ce cas-là, le nombre de joueurs disponibles est automatiquement mis à jour. Tu ne verras plus que les joueurs disponibles le matin.</p>
      <p><span class="number">5</span> En cliquant sur un jour de la semaine, tu annonces être toujours disponible ces jours-là. Le calendrier se mettra automatiquement à jour les semaines suivantes. Donc si tu es généralement dispo les dimanches, clique pour toujours recevoir les notifs pour ce jour-là.</p>
      <h3>Voir les disponibilités des autres joueurs</h3>
      <p><span class="number">4</span> Annonce le nombre de joueurs de niveau proche disponibles ce jour-là. Ici 4 joueurs proches de ton niveau sont disponibles. Propose un terrain, les jours où le plus de joueurs sont disponibles.</p>
      <p><span class="number">8</span> Affiche les joueurs disponibles et contacte-les !</p>
      <p><span class="number">7</span> Nombre de courts disponibles de ton niveau. Clique sur le bouton pour voir tous les courts et les rejoindre.</p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'sendNotif'">
      <mat-expansion-panel-header>
        <mat-panel-title>Envoyer des notifications</mat-panel-title>
        <mat-panel-description
          >J'ai un court à 19 h jeudi, niveau 30 à 15/4, 19ᵉ
          arrondissement</mat-panel-description
        >
      </mat-expansion-panel-header>
      <p>Lorsque tu as un court réservé, rempli ses caractéristiques.</p>
      <img src="/assets/court.png" alt="court" style="width: 50%;">
      <p>Sur la photo ci-dessus, les joueurs de niveau 1 à 3 intéressés recevront une notification pour un court le 9 mars à 18 h dans le 1er arrondissement.</p>
      <p>
        Accepte ou non les propositions. Tu recevras leurs coordonnés, contacte-les si tu veux en savoir plus.
      </p>
      <p>Si tu acceptes un profil, le joueur recevra tes coordonnés.</p>
      <p>
        Maintenant, la balle est dans votre camp, mettez-vous d'accord sur
        l'heure et l'endroit du rendez-vous, le mode (<button
          (click)="goTo('mode')"
          mat-button
          color="primary"
        >
          Compétition</button
        >, <button (click)="goTo('mode')" mat-button color="primary">Loisir</button>), etc
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'ligue'">
      <mat-expansion-panel-header>
        <mat-panel-title>C'est quoi les ligues ?</mat-panel-title>
        <mat-panel-description
          >Groupe de niveau près de chez toi, à ton
          rythme</mat-panel-description
        >
      </mat-expansion-panel-header>
      <p>
        Début septembre et février, tu pourras rejoindre une ligue pour 1
        semestre.
      </p>
      <p>
        Une ligue est un groupe de niveau de 6-8 personnes. Chaque semaine, tu es
        mis en relation avec 1 adversaire. Tu as ensuite 1 mois pour faire le
        match et envoyer les résultats. Tu peux donc faire tes matchs dans le désordre, l'important est d'avoir fait tous tes matchs avant la fin de la ligue!
      </p>
      <p>
        Pour rejoindre une ligue, tu dois avoir fait au moins 2 matchs en mode
        compétition. On en a besoin pour connaitre ton niveau.
      </p>
      <p>
        Tu auras directement accès aux ligues de ton niveau, inscris-toi selon
        tes critères :
      </p>
      <ul>
        <li>
          Localisation ( chaque ligue est liée à un/des arrondissement(s) )
          <button (click)="goTo('Paris')" mat-button color="primary">Pas à Paris?</button>
        </li>
        <li>
          Rythme : 1 match par semaine (A/R) ou 1 match toutes les 2 semaines
          (seulement les matchs aller)
        </li>
      </ul>
      <p>
        Après 6 mois, les premiers rejoindront la ligue supérieure, les derniers seront
        relégués.
      </p>
      <p>Tous les matchs comptent en plus pour ton classement PTL.</p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'turnament'">
      <mat-expansion-panel-header>
        <mat-panel-title>C'est quoi les tournois ?</mat-panel-title>
        <mat-panel-description
          >Tournoi de Paris + Tournois de niveau</mat-panel-description
        >
      </mat-expansion-panel-header>
      <h3>Tournoi de Paris</h3>
      <p>
        Cette coupe est ouverte à tous les joueurs ayant réalisé <strong>2 matchs en
          mode compétition</strong>.
      </p>
      <p>
        Nous avons pensé son déroulement pour assurer un maximum de matchs pour
        tous, tout en évitant des matchs déséquilibrés. Pour cela :
      </p>
      <ol>
        <li>
          Les joueurs entrent dans le tournoi plus ou moins tôt selon leur
          niveau. Les joueurs avec le meilleur classement PTL, n'entreront pas
          dès le premier tour.
        </li>
        <li>
          <strong>Perdre ne signifie pas quitter le tournoi.</strong> Simplement rejoindre un
          tournoi secondaire. Ainsi, à la dernière journée du tournoi, à chacun
          son enjeu. Les finalistes joueront pour la première place. Les
          suivants pour la 3ᵉ place...et ainsi de suite jusqu'aux 2 joueurs
          ayant perdu tous leurs matchs, qui essaieront de ne pas terminer
          derniers du tournoi.
        </li>
      </ol>
      <p>
        À chaque début de tour, <strong>tous les joueurs reçoivent une notification</strong> 
        avec leur prochain adversaire (son profil, ses coordonnés). <strong>Vous avez
          alors 1 mois</strong> pour jouer votre match et envoyer les résultats. Comme
        expliqué précédemment, les 2 joueurs continueront le tournoi, le gagnant
        avec un autre vainqueur, le perdant avec un autre perdant. Tous les
        matchs devront se jouer dans Paris, sauf si les 2 joueurs se mettent
        d'accord pour un autre département.
      </p>
      <p>Tous les matchs comptent en plus pour ton classement PTL.</p>
      <h3>Tournois de niveau</h3>
      <p>Pas encore disponible</p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'mode'">
      <mat-expansion-panel-header>
        <mat-panel-title>Mode compétition ou loisir</mat-panel-title>
        <mat-panel-description
          >Tu veux taper la balle ou enchainer les sets ?</mat-panel-description
        >
      </mat-expansion-panel-header>
      <h3>Mode compétition</h3>
      <p>
        Tous les matchs de ligues et tournois doivent se faire en mode
        compétition !
      </p>
      <p>Les règles :</p>
      <p>
        Vous devez faire au moins 1 set de 3 jeux gagnants pour valider un match
        de compétition (en cas d'égalité 3-3 faire un tie-break). Nous
        recommandons cependant d'essayer de faire au minimum 1 set de 6 jeux
        gagnants. Vous pouvez faire au maximum un match en 3 sets gagnants de 6
        jeux gagnants. <strong>Mettez-vous d'accord avant de commencer</strong>.
      </p>
      <p>
        Que faire si le match n'est pas fini à la fin du temps de réservation ?
        Dans l'ordre :
      </p>
      <ol>
        <li>Réserver un autre court pour finir le match, si possible.</li>
        <li>
          Si au moins 1 set a été terminé, le joueur ayant le plus de set gagne.
          Si le nombre de sets est le même, le nombre de jeux gagnés prime.
        </li>
        <li>
          Si aucun set n'a été terminé, le joueur ayant soit 2 jeux d'avances,
          soit un break d'avance gagne.
        </li>
        <li>
          Si aucune des propositions ci-dessus ne sont applicables (par exemple,
          vous êtes à 5-5 dans le premier set), faire un tie-break en 5 points
          secs.
        </li>
      </ol>
      <p>
        Note: Nous vous recommandons de bien discuter de ces règles avant le match et de mettre une alarme 10 min avant la fin de
        la réservation pour avoir le temps de faire un éventuel tie-break....
      </p>
      <p><strong>Comment entrer un résultat ?</strong></p>
      <p>
        Le gagnant entre le résultat, mais il doit être validé ensuite par le
        perdant. Déterminez d'abord le gagnant selon les règles au-dessus.
        Entrez, ensuite, tous les sets commencés même ceux qui n'ont pas été
        terminés. Par exemple, s'il y a 6-3 3-0, rentrez bien le 3-0 du deuxième
        set. C'est important, car l'algorithme de classement prend en compte le
        gagnant, mais aussi le pourcentage de jeux gagnés. Donc perdre 6-2, fera
        moins baisser ton classement que perdre 6-0.
      </p>
      <p>
        Tous les matchs de compétition impacts ton classement PTL. C'est ce
        classement qui conditionne ta participation à des ligues et tournois
        de ton niveau. Encore une fois, chaque jeu compte donc bats-toi sur
        tous les points.
      </p>
      <p>
        Attention, ton nouveau classement dépend aussi du niveau de ton
        adversaire. Si tu joues contre des joueurs trop faibles, même une
        victoire pourrait ne pas te rapporter de points.
      </p>
      <h3>Mode loisir</h3>
      <p>
        Hors ligues ou tournois, il est possible de jouer en mode loisir. Pour
        se détendre, se remettre en forme, s'entrainer. On vous demandera quand
        même de nous donner un feedback après le match :
      </p>
      <ul>
        <li>Je cherche des joueurs plutôt meilleurs</li>
        <li>Le niveau était équivalent</li>
        <li>Je cherche des joueurs plutôt moins bons</li>
      </ul>
      <p>
        L'idée, c'est de pouvoir vous faire matcher avec des adversaires de votre
        niveau la prochaine fois !
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'Paris'">
      <mat-expansion-panel-header>
        <mat-panel-title>Pas à Paris ?</mat-panel-title>
        <mat-panel-description
          >Tu peux tout de même participer</mat-panel-description
        >
      </mat-expansion-panel-header>
      <p>
        Aujourd'hui la première version de la plateforme a été pensée pour les
        joueurs parisiens, pour en faciliter la logistique. Il est possible que
        cela change selon les retours que nous avons et le nombre de joueurs
        hors de Paris inscris sur la plateforme. Paris Tennis Ligue est tout de
        même utilisable dès aujourd'hui par tous. On t'explique comment gérer
        les quelques difficultés que tu vas rencontrer.
      </p>
      <h3>Recevoir des notifs</h3>
      <p>
        Oui, en effet les notifs ne proposent des filtres que pour les
        arrondissements de Paris. Pour l'instant, on te propose de choisir les
        arrondissements les plus proches de chez toi. Tu habites Boulogne ? Reçois
        les notifs pour le 16ème et le 15ème. Il est même possible que tu
        reçoives finalement une notification pour un court dans ta ville (voir
        ci-dessous). On rajoutera peut-être dans une prochaine version, des
        filtres pour les départements autour de Paris.
      </p>
      <h3>Proposer un court hors de Paris</h3>
      <p>
        Sélectionne l'arrondissement le plus proche du court. Le 16ème pour
        Boulogne par exemple. Dans la partie infos supplémentaires, <strong>commence par
          dire que le court n'est pas à Paris.</strong> 
      </p>
      <h3>Les ligues</h3>
      <p>
        Rejoins la ligue la plus proche de chez toi. On ouvrira sûrement des
        ligues pour les départements autour de Paris selon la demande.
        Attention, si tu fais partie d'une ligue dans Paris, les matchs devront
        se jouer à Paris dans la zone de la ligue (Paris Sud par exemple), sauf
        si ton adversaire est d'accord pour se déplacer.
      </p>
      <h3>Les tournois</h3>
      <p>
        C'est la même chose pour les tournois et le tournoi de Paris. Priorité aux
        matchs dans Paris, sauf accord entre les 2 adversaires.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'double'">
      <mat-expansion-panel-header>
        <mat-panel-title>Jouer en double</mat-panel-title>
        <mat-panel-description>Possible en mode loisir</mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Tu peux accepter jusqu'à 3 personnes quand tu proposes un court. Si tu
        veux organiser un double, <strong>n'oublie pas de l'écrire clairement dans la
          section infos supplémentaires.</strong>
      </p>
      <p>
        Si tu organises un double, tu ne pourras pas mettre de
        résultat et le match ne pourra pas compter pour ton classement PTL
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 'ranking'">
      <mat-expansion-panel-header>
        <mat-panel-title>Classement PTL</mat-panel-title>
        <mat-panel-description>Mis à jour après chaque match pour voir ton évolution de manière instantanée</mat-panel-description>
      </mat-expansion-panel-header>
      <p>Le classement Paris Tennis Ligue (PTL) permet de te situer parmi les joueurs inscrits et donc de trouver facilement des joueurs de ton niveau. Il te permet aussi de participer à des tournois et ligues de ton niveau.</p>
      <h3>Chaque jeu compte</h3>
      <p>
        Attention : Chaque jeu compte, pas seulement le résultat. Donc accroche-toi si tu perds pour éviter un écart trop grand. Et au contraire essaie de creuser l'écart si tu gagnes.
      </p>
      <h3>Mis à jour à chaque match</h3>
      <p>
        Ton classement est mis à jour à chaque match. Donc tu verras ton classement évoluer dès que tu confirmes un résultat.
      </p>
      <h3>Facile à lire</h3>
      <p>Facile à lire avec une échelle de 1 à 10. Les débutants commencent à un niveau de 1, les meilleurs joueurs pourront monter jusqu'à 10 !</p>
      <h3>Équivalence FFT</h3>
      <p>Un niveau PTL représente environ 2 niveaux FFT. Donc on te conseille de chercher des adversaires de ton niveau +-1. Imaginons que tu es 30/2 FFT et niveau 3 PTL. Chercher des adversaires entre le niveau 2 et 4 revient ainsi à chercher des joueurs entre 30/4 et 30.</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
