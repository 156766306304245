<h2 mat-dialog-title>Joueurs disponibles</h2>
<mat-dialog-content class="mat-typography" [formGroup]="usersForm">
  <div>
    Ces joueurs ont au moins une disponibilité avec toi ce jour là.
  </div> 
  <div>
    Tu peux les sélectionner et leur envoyer un message.
  </div>
  <div style="margin-top: 20px;" *ngFor="let user of data.users">
    <p><mat-checkbox [formControlName]="user.user!.id">
      {{user.user!.surname}} {{user.user!.name}} (PTL:{{user.user!.levelPTL}} | FFT:{{user.user!.levelFFT}}) {{getDispo(user)}} <span style="font-size: 0.6rem; color: grey; font-style: italic;">{{getLastActive(user.user!.updatedAt)}}</span>
    </mat-checkbox></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fermer</button>
  <button (click)="sendMessages()" mat-button cdkFocusInitial>Envoyer un message</button>
</mat-dialog-actions>
