<a
  style="position: absolute; z-index: 1000; top: 10px; right: 10px;"
  routerLink=""
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</a>

<ng-container *ngIf="chatService.isInstantiated">
  <stream-channel-list></stream-channel-list>
  <stream-channel>
    <stream-channel-header></stream-channel-header>
    <stream-message-list></stream-message-list>
    <stream-notification-list></stream-notification-list>
    <stream-message-input></stream-message-input>
    <stream-thread name="thread">
      <stream-message-list
        name="thread-message-list"
        mode="thread"
      ></stream-message-list>
      <stream-message-input
        mode="thread"
        name="thread-message-input"
      ></stream-message-input>
    </stream-thread>
  </stream-channel>
</ng-container>