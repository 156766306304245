<h2 mat-dialog-title>Résultat</h2>
<mat-dialog-content class="mat-typography" [formGroup]="resultForm">
  <mat-error *ngIf="error" style="display: block; margin-bottom: 16px;">{{error}}</mat-error>
  <div class="button-toggle" appearance="fill">
    <mat-button-toggle-group formControlName="competition" name="type" aria-label="type">
      <mat-button-toggle [value]="false">Loisir</mat-button-toggle>
      <mat-button-toggle [value]="true">Compétition</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div style="margin-bottom: 16px;" *ngIf="resultForm.value.competition">
      <mat-button-toggle-group formControlName="won" name="type" aria-label="type">
        <mat-button-toggle [value]="true">J'ai gagné</mat-button-toggle>
        <mat-button-toggle [value]="false">J'ai perdu</mat-button-toggle>
      </mat-button-toggle-group>
  </div>
  <div *ngIf="resultForm.value.competition && resultForm.value.won !== null" >
      <button (click)="addSet()" [disabled]="$any(resultForm).controls.score.controls.length === 5" mat-button>Ajouter un set</button>
      <div style="display: flex;">
        <!-- <div style="display: flex; flex-direction: column; justify-content: space-around; margin-bottom: 30px; margin-right: 8px;">
          <div>Moi</div>
          <div>Mon adversaire</div>
        </div> -->
        <div *ngFor="let setControl of $any(resultForm).controls.score.controls; let i = index" style="display: flex; flex-direction: column; margin-right: 4px;">
          <div style="display: flex; align-items: baseline;">
            <div *ngIf="i === 0" style="width: 100px;">Moi</div>
            <mat-form-field style="width: 40px;">
              <input [formControl]="setControl.controls.me" matInput type="number" min="0" max="7" >
            </mat-form-field>
          </div>
          <div style="display: flex;align-items: baseline;">
            <div *ngIf="i === 0" style="width: 100px;">Mon adversaire</div>
          <mat-form-field style="width: 40px;">
            <input [formControl]="setControl.controls.opponent" matInput type="number" min="0" max="7" >
          </mat-form-field>
          </div>
          <button *ngIf="i !== 0" (click)="deleteSet(i)" mat-icon-button color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
  </div>
  <div *ngIf="resultForm.value.competition === false">
    <mat-form-field style="width: 100%;" appearance="fill">
      <mat-label>Ton avis</mat-label>
      <mat-select formControlName="loisir" name="loisir">
        <mat-option [value]="0">Je cherche des joueurs avec un niveau supérieur</mat-option>
        <mat-option [value]="1">Le niveau était égal</mat-option>
        <mat-option [value]="2">Je cherche des joueurs avec un niveau inférieur</mat-option>
      </mat-select>
      <mat-hint>Ton avis est privé, il ne sera pas partagé avec ton adversaire.</mat-hint>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button (click)="submitResult()">{{resultForm.value.competition === false ? 'Sauvegarder' : 'Envoyer pour validation'}}</button>
</mat-dialog-actions>