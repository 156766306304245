import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { ChatService } from '../chat/chat.service';
import { AddCourtDialogComponent } from '../dashboard/add-court-dialog/add-court-dialog.component';
import { Calendar, User, Notification } from '../dashboard/dashboard.component';
import { ContactComponent, getDispoFromString } from './contact/contact.component';
import { FaqCalendarComponent } from './faq/faq.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit, OnDestroy {
  user!: User;
  // calendar with number of player
  calendar: Calendar = {} as any;
  calendarFiltered: Calendar = {} as any;
  // calendar for current user
  calendarUser!: Notification;
  calendarDays = [Array.from(Array(7).keys()), Array.from(Array(7).keys()), Array.from(Array(7).keys())];
  today = (new Date()).getDay();
  WEEK = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  DAY_WEEK = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]
  // subject with debounce to dave after 10s
  saveSelected$ = new Subject();
  courtsAvailable!: {[key:string]: number}
  levelMinControl!: FormControl;
  levelMaxControl!: FormControl;
  minSubscription!: Subscription;
  maxSubscription!: Subscription;
  formSubscription!: Subscription;

  selectOpen: number | null = null;
  dayForm = new FormGroup({
    morning: new FormControl(false),
    noon: new FormControl(false),
    afternoon: new FormControl(false),
    night: new FormControl(false),
  })
  calendarChanged = false;


  constructor(
    public dialog: MatDialog, 
    private http: HttpClient, 
    private _snackBar: MatSnackBar, 
    private userService: UserService, 
    private detectionRef: ChangeDetectorRef,
    private chatService: ChatService,
    private _router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = this.userService.user!;
    this.levelMinControl = new FormControl(Math.max(0, this.user.levelPTL - 2));
    this.levelMaxControl = new FormControl(Math.min(10, this.user.levelPTL + 2));
    this.minSubscription = this.levelMinControl.valueChanges.pipe(debounceTime(100)).subscribe(_ => {this.filterPlayers(); this.detectionRef.detectChanges()});
    this.maxSubscription = this.levelMaxControl.valueChanges.pipe(debounceTime(100)).subscribe(_ => {this.filterPlayers(); this.detectionRef.detectChanges()});
    this.getCalendar();
    if ((new Date()).getHours() > 18) {
      this.today = this.today === 7 ? 1 : this.today + 1; 
    }
    this.saveSelected$.pipe(debounceTime(3000)).subscribe(save => {
      this.saveSelected();
    });
    this.formSubscription = this.dayForm.valueChanges.subscribe(data => {
      const value = `${data.morning ? 1 : 0}${data.noon ? 1 : 0}${data.afternoon ? 1 : 0}${data.night ? 1 : 0}`;
      const dayOfMonth = this.getMomentDayOfMonth(this.selectOpen!);
      this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] = value;
      this.calendarChanged = true;
    });
    const faq = localStorage.getItem('faq');
    if (!faq) {
      this.dialog.open(FaqCalendarComponent);
    }
  }

  ngOnDestroy(): void {
    this.minSubscription.unsubscribe();
    this.maxSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.saveSelected()
  }

  async addCourt(day: number) {
    const dialogRef = this.dialog.open(AddCourtDialogComponent, {
      data: {user: this.user, initialDate: this.getDateFormatted(day), level: [this.levelMinControl.value, this.levelMaxControl.value]}
    });

    const court = await dialogRef.afterClosed().pipe(take(1)).toPromise();
    if (court) {
      this.http.post('/api-ptl/dashboard/court', court)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Le court n'a pas pu être ajouté", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.getCalendar();
        }
      });
    }
    
  }

  getDayOfMonth(day: number) {
    const mappingDays = {
     '-2': 'Avant-hier',
     '-1': 'Hier',
     '0': 'Aujourd\'hui',
     '1': 'Demain',
     '2': 'Après-demain',
    }
    const date = this.getMomentDayOfMonth(day);
    const realToday = this.today + ((new Date()).getHours() > 18 ? -1 : 0)
    return day > realToday - 3 && day < realToday + 3 ?  (mappingDays as any)[day - realToday] : date.locale('fr').format('Do MMMM');
  }

  getNumberOfUser(day: number) {
    const dayOfMonth = this.getMomentDayOfMonth(day);
    const dayUser = this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] as string;
    const users = (this.calendarFiltered['day' + dayOfMonth.date() as keyof Calendar] as Notification[])?.filter(notif => {
      return [...dayUser].some((part, index) => {
        return part === '1' && notif['day' + dayOfMonth.date() as keyof Calendar] && notif['day' + dayOfMonth.date() as keyof Calendar][index] === part;
      });
    });
    return (users && users.length) || 0;
  }

  selectDay(day: number) {
    const dayOfMonth = this.getMomentDayOfMonth(day);
    this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] = this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] === '0000' ? '1111' : '0000';
    this.saveSelected$.next();
    this.calendarChanged = true;
  }

  /**
   * Open dialog to contact player, then send notif to selected player and start chat with them
   * @param day 
   * @param event 
   */
  async chatWithPlayers(day: number, event: Event) {
    event.stopPropagation();
    const dayOfMonth = this.getMomentDayOfMonth(day);
    const dayUser = this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] as string;
    const users = (this.calendarFiltered['day' + dayOfMonth.date() as keyof Calendar] as Notification[])?.filter(notif => {
      return [...dayUser].some((part, index) => {
        return part === '1' && notif['day' + dayOfMonth.date() as keyof Calendar] && notif['day' + dayOfMonth.date() as keyof Calendar][index] === part;
      });
    });
    const dialogRef = this.dialog.open(ContactComponent, {
      data: {users, day: dayOfMonth}
    });

    const userIds = await dialogRef.afterClosed().pipe(take(1)).toPromise() as string[];
    if (userIds && userIds.length) {
      this.http.post('/api-ptl/calendar/notifPlayers', {
        users: userIds,
        day: dayOfMonth.locale('fr').format('dddd DD MMMM')
      })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(async (res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          await this.chatService.createNewChannels(userIds.map(userId => {
            const user = users.find(user => user.user!.id.toString() === userId)!;
            return {
              id: +userId,
              name: user.user!.surname
            }
          }),
          `Je suis disponible pour jouer ${dayOfMonth.locale('fr').format('dddd DD MMMM')} ${getDispoFromString(dayUser)}`);
          this._router.navigate([`/chat`]);
        }
      });
    }
  }

  getNumberOfCourts(day: number) {
    const dayOfMonth = this.getMomentDayOfMonth(day);
    return this.courtsAvailable[dayOfMonth.format('DD/MM/YYYY')] || 0;
  }

  selected(day: number) {
    const dayOfMonth = this.getMomentDayOfMonth(day);
    return this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] !== '0000';
  }

  isSelected(day: number, part: number) {
    const dayOfMonth = this.getMomentDayOfMonth(day);
    return this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] && this.calendarUser['day' + dayOfMonth.date() as keyof Calendar][part] === '1' ? 'green' : 'red';
  }

  selectDayOfWeek(day: number) {
    for (let index = 1; index <= 31; index++) {
      const dayOfMonth = this.getMomentDayOfMonth(index);
      // after today and same day of the week (moment sunday is 0, for us sunday is 7)
      if (dayOfMonth.isSameOrAfter(moment(), 'day') && dayOfMonth.day() === ((day + 1) % 7)) {
        this.calendarUser['day' + dayOfMonth.date() as keyof Calendar] = (this.calendarUser as any)[(this.DAY_WEEK as any)[day]] ? '0000' : '1111';
      }
    }
    (this.calendarUser as any)[(this.DAY_WEEK as any)[day]] = !(this.calendarUser as any)[(this.DAY_WEEK as any)[day]];
    this.saveSelected$.next();
    this.calendarChanged = true;
  }

  openSelect(day: number, event: Event) {
    event.stopPropagation();
    const dayOfMonth = this.getMomentDayOfMonth(day);
    const calendarUserDay = this.calendarUser['day' + dayOfMonth.date() as keyof Calendar];
    this.dayForm.patchValue({
      morning: calendarUserDay[0] === '1',
      noon: calendarUserDay[1] === '1',
      afternoon: calendarUserDay[2] === '1',
      night: calendarUserDay[3] === '1',
    }, {emitEvent: false})
    this.selectOpen = day;
  }

  closeSelect() {
    this.selectOpen = null;
    this.saveSelected$.next();
    this.dayForm.reset(undefined, {emitEvent: false});
  }

  getDateFormatted(day: number) {
    return this.getMomentDayOfMonth(day).toISOString();
  }

  private async getCalendar() {
    const {calendar, calendarPlayers, courts} = await this.http.get(`/api-ptl/calendar`)
    .pipe(
      take(1),
      catchError((error) => {
        this._snackBar.open("Erreur", 'Fermer');
        return throwError(error);
      })
    ).toPromise() as any;
    this.calendar = calendarPlayers;
    this.calendarUser = calendar;
    this.courtsAvailable = (courts as {date_format: string, level: number[]}[]).reduce((courts: {[key:string]: number}, date) => {
      if (date.level[0] <= this.user.levelPTL && this.user.levelPTL <= date.level[1]) {
        if (courts[date.date_format]) {
          courts[date.date_format]++;
        } else {
          courts[date.date_format] = 1;
        }
      }
      return courts;
    }, {} as any);
    this.filterPlayers();
    this.detectionRef.detectChanges();
  }

  private saveSelected() {
    if (this.calendarChanged) {
      this.http.put(`/api-ptl/calendar`, this.calendarUser)
      .pipe(
        catchError((error) => {
          this._snackBar.open("Les changements du calendrier n'ont pas été pris en compte", 'Fermer');
          return throwError(error);
        })
      )
      .subscribe((res) => {
        const resTyped = res as any
        if (resTyped?.message === 'ok') {
          this.calendarChanged = false;
          this._snackBar.open("Le calendrier a bien été sauvé", '', {
            duration: 2000
          });
        }
      });
    }
  }

  private getMomentDayOfMonth(day:number) {
    const realToday = this.today + ((new Date()).getHours() > 18 ? -1 : 0)
    return day < this.today ? moment().subtract(realToday - day, 'day') : moment().add(day - realToday, 'day');
  }

  private filterPlayers() {
    this.calendarFiltered = JSON.parse(JSON.stringify(this.calendar));
    for (let day = 1; day <= 31; day++) {
      if (this.calendarFiltered['day' + day as keyof Calendar]) {
        this.calendarFiltered['day' + day as keyof Calendar] = (this.calendarFiltered['day' + day as keyof Calendar] as Notification[]).filter(notification => {
          return this.levelMinControl.value <= notification.user!.levelPTL && notification.user!.levelPTL <= this.levelMaxControl.value;
        });
      }
    }
  }
}
