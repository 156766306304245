import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { User } from '../page/dashboard/dashboard.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user?: User;

  constructor(private http: HttpClient) {
   }

  async getUserData(deleteCache?: boolean) {
    if (!this.user || deleteCache) {
      const res = await this.http.get('/api-ptl/user').pipe(take(1)).toPromise() as any;
      if (res) {
        this.user = res.user;
      }
    }
    
  }
}
