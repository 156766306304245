<mat-toolbar>
  <a
    routerLink=""
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>{{user.surname}} {{user.name}}</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div class="content">
  <mat-tab-group (selectedTabChange)="getGameHistory()" animationDuration="0ms">
    <mat-tab label="Info Joueur">
      <mat-card class="card">
        <mat-card-content>
          <div style="margin-bottom: 10px;">Code de parrainage: <strong>{{userPage.sponsorCode}}</strong></div> 
          <mat-chip-list *ngIf="!isLoggedUser">
            <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
              <mat-chip>{{userPage.sexe === 'H' ? 'Homme' : 'Femme'}}</mat-chip>
              <mat-chip color="primary" selected>Classement PTL: {{userPage.levelPTL | number: '1.1-1'}}</mat-chip>
              <mat-chip>Classement FFT: {{userPage.levelFFT}}</mat-chip>
            </div>
          </mat-chip-list>
          <form *ngIf="isLoggedUser" [formGroup]="userForm!">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input formControlName="email" maxlength="50" matInput type="email">
              </mat-form-field>
              <button (click)="saveField('email')" [disabled]="isSaveDisabled('email')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Prénom</mat-label>
                <input formControlName="surname" maxlength="30" matInput>
              </mat-form-field>
              <button (click)="saveField('surname')" [disabled]="isSaveDisabled('surname')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Nom</mat-label>
                <input formControlName="name" maxlength="50" matInput>
              </mat-form-field>
              <button (click)="saveField('name')" [disabled]="isSaveDisabled('name')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Téléphone</mat-label>
                <input formControlName="phone" maxlength="15" matInput [errorStateMatcher]="matcher">
              </mat-form-field>
              <button (click)="saveField('phone')" [disabled]="isSaveDisabled('phone')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Classement FFT</mat-label>
                <input formControlName="levelFFT" maxlength="15" matInput>
              </mat-form-field>
              <button (click)="saveField('levelFFT')" [disabled]="isSaveDisabled('levelFFT')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="fill">
                <mat-label>Nouveau mot de passe</mat-label>
                <input formControlName="password" maxlength="50" matInput type="password" [errorStateMatcher]="matcher">
              </mat-form-field>
              <button (click)="saveField('password')" [disabled]="isSaveDisabled('password')" mat-icon-button color="primary">
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Historique des matchs">
      <div *ngIf="games" style="margin: 12px;">
        <ng-container *ngFor="let game of games">
          <div *ngIf="game.court.games && game.court.games.length > 1" style="margin-top: 12px;">
            {{getPrettyDate(game.court.dateAndTime)}}
            <mat-card >
              <mat-card-content>
                <div style="display: flex;">
                  <div [ngClass]="isRedOrGreen(game)" style="margin-right: 4px; font-weight: bold;">{{getPrettyResult(game)}}</div>
                  <div style="margin-right: 4px;">{{getPrettyResultInfo(game)}}</div>
                  <div>{{contreOuAvec(game)}}</div>
                </div>
               <div *ngFor="let opponent of opponents(game)" style="display: flex;">
                  <div style="margin-right: 4px;">{{opponent.user.surname}}</div>
                  <div style="margin-right: 4px;">(PTL: {{opponent.user.levelPTL}} |</div>
                  <div>FFT: {{opponent.user.levelFFT}})</div>
               </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
