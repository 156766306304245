<div class="title">
  <div>
    <h1 class="principal">PARIS TENNIS LIGUE</h1>
    <h2 class="subtitle">Ligue amateur de tennis</h2>
  </div>
</div>
<div [ngClass]="{'signin': selected.value === 1}" class="content">
  <div class="presentation">
    <div class="annonce">
      <mat-card class="block">
        <h2 style="color: rgba(43, 70, 118, 255)">Joue avec des joueurs de ton niveau</h2>
        <h3>Débutant - Intermédiaire - Avancé</h3>
      </mat-card>
      <mat-card class="block">
        <h2 style="color: rgba(43, 70, 118, 255)">Près de chez toi - À ton rythme</h2>
        <h3>Tu réserves le court</h3>
      </mat-card>
      <mat-card class="block">
        <h2 style="color: rgba(43, 70, 118, 255)">Avec de l'enjeu</h2>
        <h3>Monte dans la ligue, accède aux tournois</h3>
      </mat-card>
    </div>
  </div>
  <div class="inscription">
    <div class="forms">
      <mat-card>
        <mat-tab-group 
          [selectedIndex]="selected.value"
          (selectedIndexChange)="selected.setValue($event)" 
          mat-align-tabs="start" 
          dynamicHeight>
          <mat-tab label="Connexion">
            <div class="form" [formGroup]="formLogin">
                <div class="email">
                  <label for="email"></label>
                  <input [ngClass]="{'error': hasError(formLogin.controls.email)}" id="email" type="email" placeholder="Email" formControlName="email">
                  <div *ngIf="hasError(formLogin.controls.email)" class="hint-error">{{getFirstError(formLogin.controls.email)}}</div>
                </div>
                <div class="password">
                  <label for="password"></label>
                  <input [ngClass]="{'error': hasError(formLogin.controls.password)}" id="password" type="password" placeholder="Mot de passe" formControlName="password">
                </div>
                <button mat-raised-button (click)="login()" [disabled]="disabledLogin" style="margin-bottom: 8px;">Se connecter</button>
                <button mat-flat-button (click)="goToReset()">Mot de passe oublié</button>
                <button *ngIf="needNewConfirm" [disabled]="!formLogin.controls.email.valid" mat-flat-button (click)="sendNewConfirmEmail()">Envoyer un nouveau mail de confirmation</button>
            </div>
          </mat-tab>
          <mat-tab label="Inscription">
            <div class="form" [formGroup]="formSignup">
              <div class="email">
                <label for="email"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.email)}" id="email" type="email" placeholder="Email" formControlName="email">
                <div *ngIf="hasError(formSignup.controls.email)" class="hint-error">{{getFirstError(formSignup.controls.email)}}</div>
              </div>
              <div class="surname">
                <label for="surname"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.surname)}" maxlength="30" id="surname" type="text" placeholder="Prénom" formControlName="surname">
                <div *ngIf="hasError(formSignup.controls.surname)" class="hint-error">{{getFirstError(formSignup.controls.surname)}}</div>
              </div>
              <div class="name">
                <label for="name"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.name)}" maxlength="50" id="name" type="text" placeholder="Nom" formControlName="name">
                <div *ngIf="hasError(formSignup.controls.name)" class="hint-error">{{getFirstError(formSignup.controls.name)}}</div>
              </div>
              <div class="sexe">
                <mat-button-toggle-group formControlName="sexe" name="sexe" aria-label="sexe">
                  <mat-button-toggle value="H">Homme</mat-button-toggle>
                  <mat-button-toggle value="F">Femme</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="level">
                <label for="level"></label>
                <select [ngClass]="{'error': hasError(formSignup.controls.level)}" name="level" id="level" formControlName="level">
                  <option value="">-- Choisis ton niveau --</option>
                  <option value="Débutant">Je débute (-1 an)</option>
                  <option value="Non classé">J'ai un peu joué (+1 an) mais jamais été classé</option>
                  <option value="40">40</option>
                  <option value="30/5">30/5</option>
                  <option value="30/4">30/4</option>
                  <option value="30/3">30/3</option>
                  <option value="30/2">30/2</option>
                  <option value="30/1">30/1</option>
                  <option value="30">30</option>
                  <option value="15/5">15/5</option>
                  <option value="15/4">15/4</option>
                  <option value="15/3">15/3</option>
                  <option value="15/2">15/2</option>
                  <option value="15/1">15/1</option>
                  <option value="15">15</option>
                  <option value="5/6">5/6</option>
                  <option value="4/6">4/6</option>
                  <option value="3/6">3/6</option>
                  <option value="2/6">2/6</option>
                  <option value="1/6">1/6</option>
                  <option value="0">0</option>
                </select>
              </div>
              <div class="phone">
                <label for="phone"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.phone)}" maxlength="15" id="phone" type="text" placeholder="Téléphone (optionnel)" formControlName="phone">
                <div class="hint">Utilisé pour t'envoyer des notifications sur whatsapp (au lieu d'un mail)</div>
              </div>
              <div class="phone">
                <label for="sponsor"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.sponsor)}" id="sponsor" type="text" placeholder="Code de parrainage (optionnel)" formControlName="sponsor">
                <div class="hint">Si quelqu'un t'a recommendé PTL, demande-lui son code de parrainage</div>
              </div>
              <div class="password">
                <label for="password"></label>
                <input [ngClass]="{'error': hasError(formSignup.controls.password)}" maxlength="50" id="password" type="password" placeholder="Mot de passe" formControlName="password">
                <div *ngIf="hasError(formSignup.controls.password)" class="hint-error">{{getFirstError(formSignup.controls.password)}}</div>
              </div>
              <button mat-raised-button (click)="subscribe()" [disabled]="disabledSignup">S'inscrire</button>
          </div>
          </mat-tab>
          <mat-tab label="Réinitialiser mot de passe">
            <div class="form" [formGroup]="formPasswordReset">
              <div class="email">
                <label for="email"></label>
                <input [ngClass]="{'error': hasError(formPasswordReset.controls.email)}" id="email" type="email" placeholder="Email" formControlName="email">
                <div *ngIf="hasError(formPasswordReset.controls.email)" class="hint-error">{{getFirstError(formPasswordReset.controls.email)}}</div>
              </div>
              <button mat-raised-button (click)="resetPassword()" [disabled]="disabledPasswordReset" style="margin-bottom: 8px;">Réinitialiser</button>
              <button *ngIf="wasReset" mat-flat-button (click)="goToNew()">Nouveau mot de passe</button>
          </div>
          </mat-tab>
          <mat-tab label="Nouveau mot de passe">
            <div class="form" [formGroup]="formPasswordNew">
              <div class="password">
                <label for="password"></label>
                <input [ngClass]="{'error': hasError(formPasswordNew.controls.password)}" id="password" type="password" placeholder="Nouveau mot de passe" formControlName="password">
                <div *ngIf="hasError(formPasswordNew.controls.password)" class="hint-error">{{getFirstError(formPasswordNew.controls.password)}}</div>
              </div>
              <div class="code">
                <label for="code"></label>
                <input [ngClass]="{'error': hasError(formPasswordNew.controls.code)}" id="code" type="text" placeholder="Code reçu" formControlName="code">
                <div *ngIf="hasError(formPasswordNew.controls.code)" class="hint-error">{{getFirstError(formPasswordNew.controls.code)}}</div>
              </div>
              <button mat-raised-button (click)="changePassword()" [disabled]="disabledPasswordNew">Changer le mot de passe</button>
          </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>
<div class="contact"><a mat-button href="mailto:contact@paris-tennis-ligue.fr">contact@paris-tennis-ligue.fr</a></div>