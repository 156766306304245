<h2 mat-dialog-title>Configure tes notifications</h2>
<mat-dialog-content class="mat-typography" [formGroup]="notifForm">
    <div class="toggle">
      <mat-slide-toggle
          formControlName="activated">
          <ng-container *ngIf="!notifForm.value.activated">Notifs desactivées</ng-container> 
          <ng-container *ngIf="notifForm.value.activated">Notifs activées</ng-container> 
      </mat-slide-toggle>
      <div *ngIf="!notifForm.value.activated" class="hint">Tu ne recevras plus aucun mail quand des parties s'ouvrent</div>
      <div *ngIf="notifForm.value.activated" class="hint">Tu recevras uniquement des mails selon les critères ci-dessous</div>
    </div>

    <div class="toggle">
      <mat-slide-toggle
          formControlName="useWhatsapp" [disabled]="!notifForm.value.activated">
          <ng-container *ngIf="!notifForm.value.useWhatsapp">Notifications whatsapp désactivées</ng-container> 
          <ng-container *ngIf="notifForm.value.useWhatsapp">Notifications whatsapp activées</ng-container> 
      </mat-slide-toggle>
      <div *ngIf="!notifForm.value.useWhatsapp" class="hint">Tu recevras les notifications par mail</div>
      <div *ngIf="notifForm.value.useWhatsapp" class="hint">Tu recevras les notifications sur whatsapp. Si l'envoi ne fonctionne pas (mauvais numéro, etc), tu recevras quand même les notifications par mail</div>
      <div *ngIf="showErrorWhatsapp" class="hint" style="color: red;">Ton numéro doit commencer par + suivi par l'indicateur du pays (exemple France: +33)</div>
    </div>
    
    <mat-divider></mat-divider>
    <div class="levelSlider">
      <div>
        <mat-label for="levelSlider"
          >Choisis le niveau min et max de tes adversaires</mat-label
        >
        <div class="hint">
          1 niveau PTL correspond à environ 2 niveau FFT, donc on te suggère de filtrer par ton niveau -2 +2.
        </div>
      </div>
      <div class="filter" style="display: flex; align-items: center;">
        <div class="slider" style="display: flex; flex-direction: column; align-items: center;">
          <div style="font-size: 0.8rem; margin-bottom: -12px;">Min: {{$any(notifForm).controls.level.controls[0].value | number: '1.1-1'}}</div>
          <mat-slider
            class="example-margin"
            [formControl]="$any(notifForm).controls.level.controls[0]"
            [disabled]="!notifForm.value.activated"
            (input)="onMinChange($event)"
            [max]="(user.levelPTL - 0.5) | number: '1.1-1'"
            [min]="0"
            [step]="0.5"
            [vertical]="false">
          </mat-slider>
        </div>
        <div style="display: flex;flex-direction: column; align-items: center; justify-content: center; width: 20%;">
          <div style="font-size: 0.8rem; line-height: 0.8rem;">Ton niveau:</div>
          <div style="font-size: 0.8rem; line-height: 0.8rem;">{{user.levelPTL | number: '1.1-1'}}</div>
        </div>
        <div class="slider" style="display: flex; flex-direction: column; align-items: center;">
          <div style="font-size: 0.8rem; margin-bottom: -12px;">Max: {{$any(notifForm).controls.level.controls[1].value  | number: '1.1-1'}}</div>
          <mat-slider
            class="example-margin"
            [formControl]="$any(notifForm).controls.level.controls[1]"
            [disabled]="!notifForm.value.activated"
            (input)="onMaxChange($event)"
              [max]="10"
              [min]="(user.levelPTL + 0.5) | number: '1.1-1'"
              [step]="0.5"
              [vertical]="false">
            </mat-slider>
        </div>
    </div>
    </div>
    <mat-divider></mat-divider>
    <div class="button-toggle" appearance="fill">
      <mat-label>Choisi les arrondissement où tu veux jouer:</mat-label>
      <mat-button-toggle-group formControlName="arrondissements" [disabled]="!notifForm.value.activated" name="arrondissements" aria-label="arrondissements" multiple>
        <mat-button-toggle *ngFor="let arrondissement of arrondissements; let i = index" [value]="arrondissement + 1">{{arrondissement + 1}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button (click)="submitForm()" cdkFocusInitial>Sauvegarder et remplir mon calendrier</button>
</mat-dialog-actions>