import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

interface Result {
  competition: boolean | null;
  won: boolean | null;
  score: Array<{me: number, opponent: number}> | null;
  loisir: number | null;
}

@Component({
  selector: 'app-add-result-dialog',
  templateUrl: './add-result-dialog.component.html',
  styleUrls: ['./add-result-dialog.component.scss']
})
export class AddResultDialogComponent implements OnInit {

  resultForm: FormGroup = new FormGroup({
    competition: new FormControl(null, Validators.required),
    won: new FormControl(null),
    score: new FormArray([new FormGroup({
      me: new FormControl(null, [Validators.required, Validators.max(7), Validators.min(0)]),
      opponent: new FormControl(null, [Validators.required, Validators.max(7), Validators.min(0)]),
    })]),
    loisir: new FormControl(null, Validators.required),
  });
  error?: string;

  constructor(public dialogRef: MatDialogRef<AddResultDialogComponent>) { }

  ngOnInit(): void {
  }

  addSet() {
    (this.resultForm.controls.score as FormArray).push(new FormGroup({
      me: new FormControl(null, [Validators.required, Validators.max(7), Validators.min(0)]),
      opponent: new FormControl(null, [Validators.required, Validators.max(7), Validators.min(0)]),
    }))
  }

  deleteSet(index: number) {
    (this.resultForm.controls.score as FormArray).removeAt(index);
  }

  submitResult() {
    this.error = undefined;
    const formValue: Result = this.resultForm.value;
    if (formValue.competition !== null) {
      if (formValue.competition) {
        if (formValue.won !== null) {
          if (this.resultForm.controls.score.valid) {
            this.dialogRef.close({
              competition: formValue.competition,
              won: formValue.won,
              score: formValue.score
            });
          } else {
            this.resultForm.markAllAsTouched();
          }
        } else {
          this.error = 'As-tu gagné ou perdu?'
        }
      } else {
        if (this.resultForm.controls.loisir.valid) {
          this.dialogRef.close({
            competition: formValue.competition,
            loisir: formValue.loisir
          });
        } else {
          this.resultForm.markAllAsTouched();
        }
      }
    } else {
      this.error = 'Le type de match doit être rempli'
    }
  }


}
