<h2 mat-dialog-title>Caractéristiques du court</h2>
<mat-dialog-content class="mat-typography" [formGroup]="courtForm">
  <div *ngIf="showError" style="color: red; margin-bottom: 12px;">Le formulaire n'est pas correctement ou entièrement rempli!</div>
  <div class="toggle">
    <mat-slide-toggle
        formControlName="booked">
        <ng-container *ngIf="courtForm.value.booked">J'ai déjà réservé le court</ng-container> 
        <ng-container *ngIf="!courtForm.value.booked">Je n'ai pas encore de court</ng-container> 
    </mat-slide-toggle>
    <div *ngIf="!courtForm.value.booked" class="hint">Tu ne pourras pas encore accepter de joueurs</div>
  </div>
  <div class="levelSlider">
    <div>
      <mat-label for="levelSlider"
        >Choisis le niveau min et max de tes adversaires</mat-label
      >
      <div class="hint">
        1 niveau PTL correspond à environ 2 niveau FFT, donc on te suggère de filtrer par ton niveau -1 +1. Seuls les joueurs de ce niveau pourront voir ce court
      </div>
    </div>
    <div class="filter" style="display: flex; align-items: center;">
      <div class="slider" style="display: flex; flex-direction: column; align-items: center;">
        <div style="font-size: 0.8rem; margin-bottom: -12px;">Min: {{$any(courtForm).controls.level.controls[0].value | number: '1.1-1'}}</div>
        <mat-slider
          class="example-margin"
          [formControl]="$any(courtForm).controls.level.controls[0]"
          (input)="onMinChange($event)"
          [max]="(data.user.levelPTL - 0.5) | number: '1.1-1'"
          [min]="0"
          [step]="0.5"
          [vertical]="false">
        </mat-slider>
      </div>
      <div style="display: flex;flex-direction: column; align-items: center; justify-content: center; width: 20%;">
        <div style="font-size: 0.8rem; line-height: 0.8rem;">Ton niveau:</div>
        <div style="font-size: 0.8rem; line-height: 0.8rem;">{{data.user.levelPTL | number: '1.1-1'}}</div>
      </div>
      <div class="slider" style="display: flex; flex-direction: column; align-items: center;">
        <div style="font-size: 0.8rem; margin-bottom: -12px;">Max: {{$any(courtForm).controls.level.controls[1].value  | number: '1.1-1'}}</div>
        <mat-slider
          class="example-margin"
          [formControl]="$any(courtForm).controls.level.controls[1]"
          (input)="onMaxChange($event)"
            [max]="10"
            [min]="(data.user.levelPTL + 0.5) | number: '1.1-1'"
            [step]="0.5"
            [vertical]="false">
          </mat-slider>
      </div>
  </div>
  </div>
  <mat-divider></mat-divider>
  <div class="button-toggle" appearance="fill">
    <mat-label>Choisis l'arrondissement où se trouve le court:</mat-label>
    <mat-button-toggle-group formControlName="arrondissement" name="arrondissement" aria-label="arrondissement">
      <mat-button-toggle *ngFor="let arrondissement of arrondissements; let i = index" [value]="arrondissement + 1">{{arrondissement + 1}}</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-hint>Si le court n'est pas confirmé, choisis l'arrondissement où tu voudrais jouer</mat-hint>
  </div>
  <mat-divider></mat-divider>
  <div class="time" style="display: flex; margin-top: 16px">
    <mat-form-field style="margin-top: 13px; margin-right: 8px;">
      <input
        matInput
        [ngxMatDatetimePicker]="picker"
        placeholder="Date et heure"
        formControlName="dateAndTime"
        [min]="minDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(picker)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #picker
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="enableMeridian"
      >
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Durée</mat-label>
      <mat-select name="temps" id="level" formControlName="duration">
        <mat-option [value]="60">1h</mat-option>
        <mat-option [value]="90">1h30</mat-option>
        <mat-option [value]="120">2h</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-hint style="margin-left: 8px;">Si le court n'est pas confirmé, choisis l'heure à laquelle tu voudrais jouer</mat-hint>
  </div>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Infos supplémentaires</mat-label>
    <textarea formControlName="description" [maxlength]="maxlength" matInput placeholder="Suzanne Lenglen, court N°7, participation 6€, court couvert"></textarea>
    <mat-hint>Lieu exact, prix, court couvert ou non, etc. Dis aussi clairement si tu veux faire un match ou juste t'entrainer</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button (click)="submitForm()" cdkFocusInitial>Sauvegarder</button>
</mat-dialog-actions>
