import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Notification, User } from '../dashboard.component';

@Component({
  selector: 'app-notif-dialog',
  templateUrl: './notif-dialog.component.html',
  styleUrls: ['./notif-dialog.component.scss']
})
export class NotifDialogComponent implements OnInit, OnDestroy {
  user!: User;
  notifForm: FormGroup = new FormGroup({
    activated: new FormControl(true),
    useWhatsapp: new FormControl(false),
    level: new FormArray([new FormControl(0), new FormControl(10)]),
    arrondissements: new FormControl([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]),
  });
  arrondissements!: Array<number>;
  showErrorWhatsapp= false;

  whatsappSubscription!: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Notification, public dialogRef: MatDialogRef<NotifDialogComponent>, private userService: UserService) { }

  get optionsSlider() {
    return  {
      floor: 0,
      ceil: 10,
      step: 1,
      disabled: !this.notifForm.value.activated
    }
  }

  ngOnInit(): void {
    this.user = this.userService.user!;
    this.arrondissements = Array.from(Array(20).keys());
    (this.notifForm.controls.level as FormArray).setValue([this.user.levelPTL - 2, this.user.levelPTL + 2])
    if (this.data) {
      this.notifForm.patchValue(this.data);
    }
    this.whatsappSubscription = this.notifForm.controls.useWhatsapp.valueChanges.subscribe(useWhatsapp => {
      if (useWhatsapp && (!this.user.phone || this.user.phone[0] !== '+')) {
        this.showErrorWhatsapp = true;
        this.notifForm.controls.useWhatsapp.setValue(false, {emitEvent: false});
      } else {
        this.showErrorWhatsapp = false;
      }
    });
  }

  ngOnDestroy(): void {
      this.whatsappSubscription.unsubscribe();
  }

  submitForm() {
    this.dialogRef.close(this.notifForm.value);
  }

  onMinChange(value: MatSliderChange) {
    (this.notifForm.controls.level as FormArray).controls[0].setValue(value.value);
  }

  onMaxChange(value: MatSliderChange) {
    (this.notifForm.controls.level as FormArray).controls[1].setValue(value.value);
  }

}
