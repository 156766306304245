<mat-toolbar>
  <a
    routerLink="/"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>CALENDRIER</span>
  <div class="example-spacer"><div>Clique sur 1 jour pour annoncer que tu es disponible.</div></div>
  <div class="filter" style="display: flex; align-items: center;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <mat-slider
          class="example-margin"
          [formControl]="levelMinControl"
          [max]="user.levelPTL | number: '1.1-1'"
          [min]="0"
          [step]="0.5"
          [vertical]="false">
        </mat-slider>
        <div style="font-size: 0.7rem; margin-top: -20px;">Min: {{levelMinControl.value | number: '1.1-1'}}</div>
      </div>
      <div style="display: flex;flex-direction: column; align-items: center; justify-content: center">
        <div style="font-size: 0.7rem; line-height: 0.7rem;">Ton niveau:</div>
        <div style="font-size: 0.7rem; line-height: 0.7rem;">{{user.levelPTL | number: '1.1-1'}}</div>
        <mat-icon 
          style="font-size: 0.9rem; width: 0.9rem; height: 0.9rem; cursor: pointer;" 
          #tooltip="matTooltip"
          matTooltip="Filtre les joueurs disponibles par niveau min et max"
          matTooltipPosition="below">info_outline</mat-icon>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center;">
        <mat-slider
          class="example-margin"
          [formControl]="levelMaxControl"
            [max]="10"
            [min]="user.levelPTL | number: '1.1-1'"
            [step]="0.5"
            [vertical]="false">
          </mat-slider>
          <div style="font-size: 0.7rem; margin-top: -20px;">Max: {{levelMaxControl.value  | number: '1.1-1'}}</div>
      </div>
  </div>
</mat-toolbar>

<div *ngIf="calendarUser" class="calendar">
  <div class="week habits" style="width: 100%;">
    <div class="day" style="width: 15%; text-align: center;" *ngFor="let day of WEEK; let i = index">
      <button mat-raised-button 
              #tooltip="matTooltip"
              [matTooltip]="'Clique si tu es habituellement disponible le ' + (day | lowercase) + '. Ton calendrier se mettra automatiquement à jour dans le futur.'"
              matTooltipPosition="above"
              (click)="selectDayOfWeek(i)" 
              [color]="$any(calendarUser)[$any(DAY_WEEK)[i]] ? 'primary' : ''">
              {{day}}
      </button>
    </div>
  </div>
  <div class="week" style="width: 100%;" *ngFor="let week of calendarDays; let i = index">
    <div [ngClass]="i === 0 && day + 1 < today ? 'empty' : ''" class="day" style="width: 15%;" *ngFor="let day of week">
      <mat-card [ngClass]="selected(day + 1 + 7 * i) && (i !== 0 || day + 1 >= today) ? 'selected' : ''">
        <button (click)="selectDay(day + 1 + 7 * i)" class="button-card" mat-button>
          <div class="day-month">
            <button *ngIf="(i !== 0 || day + 1 >= today)" (click)="openSelect(day + 1 + 7 * i, $event)" class="dispos">
              <div style="display: flex;">
                <div class="dispo" [ngClass]="isSelected(day + 1 + 7 * i, 0)"></div>
                <div class="dispo" [ngClass]="isSelected(day + 1 + 7 * i, 1)"></div> 
                <div class="dispo" [ngClass]="isSelected(day + 1 + 7 * i, 2)"></div>
                <div class="dispo" [ngClass]="isSelected(day + 1 + 7 * i, 3)"></div>
              </div>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <div>{{getDayOfMonth(day + 1 + 7 * i)}}</div>
          </div>
          <div style="padding: 2rem 0; display: flex; flex-direction: column;">
            <div *ngIf="i !== 0 || day + 1 >= today">{{getNumberOfUser(day + 1 + 7 * i)}} joueurs</div>
            <div *ngIf="i !== 0 || day + 1 >= today" style="line-height: 0.9rem !important;">
              <button (click)="chatWithPlayers(day + 1 + 7 * i, $event)" [disabled]="!selected(day + 1 + 7 * i)" mat-button>
                <mat-icon 
                  style="font-size: 1.1rem; width: 1.1rem; height: 1.1rem">
                  chat
                </mat-icon>
              </button>
            </div>
          </div>
        </button>
        <div [formGroup]="dayForm" *ngIf="selectOpen === day + 1 + 7 * i" class="selector">
          <mat-icon (click)="closeSelect()" style="position: absolute; top: 0; right: 0; cursor: pointer;" class="small-icon">close</mat-icon>
          <h4>Choisis tes dispos:</h4>
          <p><mat-checkbox formControlName="morning">Matin</mat-checkbox></p>
          <p><mat-checkbox formControlName="noon">Midi</mat-checkbox></p>
          <p><mat-checkbox formControlName="afternoon">Aprèm</mat-checkbox></p>
          <p><mat-checkbox formControlName="night">Après 18h</mat-checkbox></p>
        </div>
        <div *ngIf="i !== 0 || day + 1 >= today" style="display: flex; flex-direction: column; align-items: stretch; margin: auto;">
          <button (click)="addCourt(day + 1 + 7 * i)" [disabled]="!selected(day + 1 + 7 * i)" class="button-in-card" style="margin: 2px;" mat-flat-button>
            Proposer un court
          </button>
          <a routerLink="/courts" [queryParams]="{date: getDateFormatted(day + 1 + 7 * i), returnUrl: '/calendar'}" [disabled]="!selected(day + 1 + 7 * i)" class="button-in-card" style="margin: 2px;" mat-flat-button>
            {{getNumberOfCourts(day + 1 + 7 * i)}} courts dispo<mat-icon class="small-icon">open_in_new</mat-icon>
          </a>
        </div>
      </mat-card>
    </div>
  </div>
</div>
