import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

const ERROR_MAPPING = {
  required: 'Ce champs est requis',
  email: 'Email invalide',
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  returnUrl!: string;
  selected = new FormControl(0);
  formLogin: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
  });
  formSignup: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, Validators.required]),
    surname: new FormControl(null, [ Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(/^[a-z ,.'-]+$/i)]),
    name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-z ,.'-]+$/i)]),
    sexe: new FormControl('H', [Validators.required]),
    level: new FormControl('', [Validators.required]),
    phone: new FormControl(null, [Validators.maxLength(15)]),
    sponsor: new FormControl(null),
    password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern(/^(?=.*?[0-9])(?=.*?[A-Za-z]).+$/)]),
  });
  formPasswordReset: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, Validators.required])
  });
  formPasswordNew: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern(/^(?=.*?[0-9])(?=.*?[A-Za-z]).+$/)]),
    code: new FormControl(null, [Validators.required]),
  });
  count: number = 40;
  wasReset = false;
  needNewConfirm = false;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private _router: Router, private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    const params = await this.route.queryParams.pipe(take(1)).toPromise();
    if (params && params.code) {
      this.goToNew();
      this.formPasswordNew.controls.code.setValue(params.code);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get disabledLogin() {
    return !this.formLogin.valid;
  }

  get disabledSignup() {
    return !this.formSignup.valid;
  }

  get disabledPasswordReset() {
    return !this.formPasswordReset.valid;
  }

  get disabledPasswordNew() {
    return !this.formPasswordNew.valid;
  }

  hasError(control: AbstractControl) {
    const typedControl = control as FormControl;
    return typedControl.touched && typedControl.errors;
  }

  getFirstError(control: AbstractControl) {
    const typedControl = control as FormControl;
    if (typedControl.touched && typedControl.errors && Object.keys(typedControl.errors)[0] === 'minlength') {
      return `Minimum ${typedControl.errors.minlength.requiredLength} caractères`;
    }
    if (typedControl.touched && typedControl.errors && Object.keys(typedControl.errors)[0] === 'maxlength') {
      return `Maximum ${typedControl.errors.maxlength.requiredLength} caractères`;
    }
    if (typedControl.touched && typedControl.errors && Object.keys(typedControl.errors)[0] === 'pattern') {
      if (typedControl.errors.pattern.requiredPattern === '/^(?=.*?[0-9])(?=.*?[A-Za-z]).+$/') {
        return '8 caractères, 1 lettre et 1 chiffre minimum'
      }
      return `Des caractères sont interdits`;
    }
    return typedControl.touched && typedControl.errors && ((ERROR_MAPPING as any)[Object.keys(typedControl.errors)[0] as any] as unknown as string);
  }

  public subscribe() {
    this.formSignup.markAllAsTouched();
    if (this.formSignup.valid) {
      this.http
        .post('/api-ptl/user/second', this.formSignup.value)
        .pipe(
          catchError((error) => {
            console.log(error);
            if (error.error?.code === 'unknown_sponsor') {
              this._snackBar.open("Code de parrainage incorrect", 'Fermer');
              return of(undefined);
            }
            if (error.error?.code === 'duplicate_user') {
              this._snackBar.open("Tu es déjà inscrit", 'Fermer');
              return of(undefined);
            }
            this._snackBar.open("Une erreur s'est produite", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this._snackBar.open("Inscription réussie. Tu dois confirmer ton email pour te connecter", 'Fermer');
            this.formSignup.reset();
          }
        });
    }
  }

  login() {
    this.formLogin.markAllAsTouched();
    if (this.formLogin.valid) {
      this.http
        .post('/api-ptl/user/login', this.formLogin.value, {
          withCredentials: true
        })
        .pipe(
          catchError((error) => {
            if (error.error?.code === 'unconfirmed-user') {
              this.needNewConfirm = true;
              this._snackBar.open("Tu dois d'abord confirmer ton email.", 'Fermer');
              return of(error.error?.code);
            }
            this._snackBar.open("Une erreur s'est produite", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this._router.navigateByUrl(this.returnUrl);
          }
        });
    }
  }

  resetPassword() {
    this.formPasswordReset.markAllAsTouched();
    if (this.formPasswordReset.valid) {
      this.wasReset = true;
      this.http
        .post('/api-ptl/user/reset', this.formPasswordReset.value, {
          withCredentials: true
        })
        .pipe(
          catchError((error) => {
            this._snackBar.open("Une erreur s'est produite", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this._snackBar.open("Un email a été envoyé à cette adresse!", 'Fermer');
          }
        });
    }
  }

  changePassword() {
    this.formPasswordNew.markAllAsTouched();
    if (this.formPasswordNew.valid) {
      this.http
        .post('/api-ptl/user/new', this.formPasswordNew.value, {
          withCredentials: true
        })
        .pipe(
          catchError((error) => {
            if (error.error?.code === 'unconfirmed-user') {
              this.needNewConfirm = true;
              this._snackBar.open("Tu dois d'abord confirmer ton email.", 'Fermer');
              return of(error.error?.code);
            }
            this._snackBar.open("Une erreur s'est produite", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this._snackBar.open("Le mot de passe a bien été changé", 'Fermer');
            this._router.navigate(['']);
          }
        });
    }
  }

  sendNewConfirmEmail() {
    if (this.formLogin.controls.email.valid) {
      this.http
        .post('/api-ptl/user/confirm/new', {email: this.formLogin.controls.email.value}, {
          withCredentials: true
        })
        .pipe(
          catchError((error) => {
            this._snackBar.open("Une erreur s'est produite", 'Fermer');
            return throwError(error);
          })
        )
        .subscribe((res) => {
          const resTyped = res as any
          if (resTyped?.message === 'ok') {
            this._snackBar.open("Un email de confirmation a été envoyé", 'Fermer');
          }
        });
    }
  }

  goToReset() {
    this.selected.setValue(2);
  }

  goToNew() {
    this.selected.setValue(3);
  }
}
