import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { EMPTY, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class FindPageUserResolver {
  constructor(private _router: Router, private http: HttpClient, private _snackBar: MatSnackBar) {};

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = +route.paramMap.get('id')!;
    return this.http.get(`/api-ptl/user/${id}`).pipe(catchError(err => {
      // TODO: use a custom id to not allow knowing max user for anyone
      this._router.navigate(['/']);
      if (err.error?.code === 'unknown_user') {
        this._snackBar.open("Joueur introuvable", 'Fermer');
        return EMPTY;
      }
      throw err;
    }));
  }
}