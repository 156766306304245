<mat-toolbar>
  <a
    [routerLink]="returnUrl"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>COURTS DISPONIBLE</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div class="courts">
  <div *ngIf="courtsInfosFormatted && !courtsInfosFormatted.length" class="empty-message">
    <div>
      <mat-icon aria-hidden="true" class="icon" color="primary">sports_tennis</mat-icon>
      <div>Pas encore de court pour ton niveau</div> 
      <a [routerLink]="['/']" [queryParams]="{addCourt: true}" mat-raised-button color="primary">Ajoute ton court</a>
    </div>
  </div>
  <mat-card *ngFor="let court of courtsInfosFormatted" class="card">
    <mat-card-title>{{court.title}}</mat-card-title>
    <mat-card-subtitle>{{court.subtitle}}</mat-card-subtitle>
    <mat-card-content>
      <p>{{court.infos}}</p>
    </mat-card-content>
    <mat-card-actions align='end'>
      <button *ngIf="!court.status" (click)="ask(court.id)" mat-button>Demander à participer</button>
      <button *ngIf="court.status === 'ASKED'" mat-button disabled>DEMANDE EN COURS</button>
      <button *ngIf="court.status === 'CONFIRMED'" mat-button disabled>CONFIRMÉ</button>
    </mat-card-actions>
  </mat-card>
</div>
