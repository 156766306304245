<h2 mat-dialog-title>Comment ça fonctionne?</h2>
<mat-dialog-content class="mat-typography">
  <div style="display: flex; width: 100%; justify-content: center;">
    <img src="/assets/calendar.png" alt="calendrier" style="width: 50%;">
  </div>
  <p>Le calendrier est une page qui te permet d'avoir beaucoup d'informations sur les disponibilités de la communauté PTL mais aussi de renseigner les autres joueurs sur tes disponibilités.</p>
  <p>Voici une vue plus précise de 2 jours du calendrier.</p>
  <img src="/assets/calendarFocus.png" alt="focus calendrier" style="width: 50%;">
  <h3>Renseigner sur mes disponibilités</h3>
  <p><span class="number">1</span> Si le jour est blanc, cela signifie que tu n'es pas disponible. L'indicateur de disponibilités dans le coin en haut à gauche est rouge.</p>
  <p>En cliquant sur le deuxième jour (demain <span class="number">3</span>), la couleur devient bleue, et à côté du <span class="number">2</span>, l'indicateur de disponibilités devient vert. Cela aura 3 conséquences :</p>
  <ul>
    <li>Tu recevras des notifications pour les nouveaux courts proposés ce jour-là</li>
    <li>Les joueurs verront que tu es disponible. Voir <span class="number">4</span></li>
    <li>Tu pourras ajouter un court ce jour-là et accéder aux courts disponibles. Voir <span class="number">6</span> et <span class="number">7</span></li>
  </ul>
  <p><span class="number">2</span> En cliquant sur l'indicateur de disponibilités, un menu apparait qui te permet de renseigner plus exactement tes disponibilités ce jour-là. Tu peux dire par exemple que tu n'es disponible que le matin. Dans ce cas-là, le nombre de joueurs disponible est automatiquement mis à jour. Tu ne verras plus que les joueurs disponibles le matin.</p>
  <p><span class="number">5</span> En cliquant sur un jour de la semaine, tu annonces être toujours disponible ces jours-là. Le calendrier se mettra automatiquement à jour les semaines suivantes. Donc si tu es généralement dispo les dimanches, clique pour toujours recevoir les notifs pour ce jour-là.</p>
  <h3>Voir les disponibilités des autres joueurs</h3>
  <p><span class="number">4</span> Annonce le nombre de joueurs de niveau proche disponibles ce jour-là. Ici 4 joueurs proches de ton niveau sont disponibles. Propose un terrain, les jours où le plus de joueurs sont disponibles.</p>
  <p><span class="number">8</span> Affiche les joueurs disponibles et contacte-les !</p>
  <p><span class="number">7</span> Nombre de courts disponibles de ton niveau. Clique sur le bouton pour voir tous les courts et les rejoindre.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="doNotShow()" mat-button mat-dialog-close cdkFocusInitial color="warn">J'ai compris ne plus montrer!</button>
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>
