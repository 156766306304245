import { Component, OnInit } from '@angular/core';
import { User } from '../dashboard/dashboard.component';
import { ChatService } from './chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  user!: User;
  isInstantiated = false;

  constructor(public chatService: ChatService) {}

  async ngOnInit() {
    await this.chatService.initChannels();
    await this.chatService.setActiveChannel();
  }
}
