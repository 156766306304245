import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Channel } from 'stream-chat';
import {
  ChatClientService,
  ChannelService,
  StreamI18nService,
} from 'stream-chat-angular';
import { User } from '../dashboard/dashboard.component';

@Injectable()
export class ChatService {

  isConnected = false;
  public isInstantiated = false;
  // TODO: move this
  apiKey = 'h68wtcn74pz6';
  chatToken?: string;
  user!: User;
  // observable that returns the number of unread messages for current user
  unreadCount?: Observable<number | undefined>;
  // channel set to become active. Chat uses this channel when instantiating to set the channel as active
  activeChannel?: string;

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private userService: UserService,
    private http: HttpClient
  ) {
    this.user = this.userService.user!;
  }

  async connectUser() {
    this.user = this.userService.user!;
    if (!this.chatToken) {
      this.chatToken = (await this.http.get('/api-ptl/user/chatToken').pipe(take(1)).toPromise() as any).chatToken;
    }
    if (!this.isConnected) {
      await this.chatService.init(this.apiKey, {id: `${this.user.id}${this.user.surname}`, name: `${this.user.surname} ${this.user.name} (PTL:${this.user.levelPTL} | FFT:${this.user.levelFFT})`, language: 'fr'}, this.chatToken);
      this.isConnected = true;
      this.unreadCount = this.channelService.channels$.pipe(map((channels) => channels?.reduce((count, channel) => count + channel.state.unreadCount, 0) || undefined));
    }
  }

  async initChannels() {
    if (!this.isInstantiated) {
      await this.connectUser();
      this.streamI18nService.setTranslation('fr');
      await this.channelService.init({
        type: 'messaging',
        members: {$in: [`${this.userService.user!.id}${this.userService.user!.surname}`]}
      });
      this.isInstantiated = true;
    } else {
      // TODO: check if this is needed (reset channel and get them to have the latest ones)
      this.channelService.reset();
      this.isInstantiated = false;
      await this.initChannels();
    }
  }

  disconnectUser() {
    this.isInstantiated = false;
    this.isConnected = false;
    this.chatToken = undefined;
    this.activeChannel = undefined;
    this.chatService.disconnectUser();
    this.channelService.reset();
  }

  async createNewChannels(userData: {name: string; id: number}[], message?: string) {
    this.activeChannel = undefined;
    await this.connectUser();
    await Promise.all(userData.map(async (userInfo, index) => {
      const channel = this.chatService.chatClient.getChannelByMembers('messaging', {
        members: [`${this.user.id}${this.user.surname}`, `${userInfo.id}${userInfo.name}`],
        name: `${this.user.surname}-${userInfo.name}`
      });
      await channel.create();
      if (message) {
        await channel.sendMessage({
          text: message
        });
      }
      if (index === 0) {
        this.activeChannel = channel.cid;
      }
      return Promise.resolve();
    }));
  }

  async setActiveChannel() {
    if (this.activeChannel) {
      const channels = (await this.channelService.channels$.pipe(take(1)).toPromise())!;
      this.channelService.setAsActiveChannel(channels.find(channel => channel.cid === this.activeChannel)!);
    }
  }
}
