import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { take } from "rxjs/operators";
import { UserService } from "./services/user.service";

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
  constructor(private _router: Router, private userService: UserService, private http: HttpClient, private _snackBar: MatSnackBar) {};

  async canActivate(route: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot) {
    if (route.parent?.queryParams?.confirm) {
      try {
        await this.http.post('/api-ptl/user/confirm', {confirmCode: route.parent?.queryParams?.confirm}).pipe(take(1)).toPromise();
        await this.userService.getUserData();
        return true;
      } catch (error) {
        this._snackBar.open('Code erroné', 'Fermer');
        return false;
      }
    }
    await this.userService.getUserData();
    if (this.userService.user) {
      return true;
    } else {
      this._router.navigate(['login'], { queryParams: { returnUrl: routerSnapshot.url }})
      return false;
    }
  }
}