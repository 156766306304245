import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqCalendarComponent {

  doNotShow() {
    localStorage.setItem('faq', 'stop');
  }

}
