import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  steps = ['PTL', 'receiveNotif', 'sendNotif', 'ligue', 'turnament', 'mode', 'Paris', 'double', 'ranking'];
  step?: string;

  constructor() { }

  ngOnInit(): void {
  }

  goTo(question: string) {
    this.step = question;
  }

}
