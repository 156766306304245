import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(private _snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        console.log(error);
        if (error.error?.code === 'auth') {
          this._snackBar.open("Tu n'es pas connecté.", 'Fermer');
          return of(undefined);
        }
        if (error.error?.code === 'wrong-credentials') {
          this._snackBar.open("Email ou mot de passe erroné", 'Fermer');
          return of(undefined);
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}